import _ from 'lodash';
import orderRecordingService from '@/services/orderRecordingService';
import Recordings from '../../model/recordings';

const state = {
  baseUrl: '/order-recordings',
  orderRecordings: [],
  downloadOrderRecordings: [],
  pagination: {},
  loading: false
};

const actions = {
  list({ dispatch, commit }, { query = {}, router, companyId }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setOrdersRecordings', { orderRecordings: [], pagination: null, companyId });
    commit('startRequest');


    orderRecordingService
      .list({ query })
      .then(response => {
        commit('setOrdersRecordings', {
          orderRecordings: response.data.rows,
          pagination: response.data.pagination,
          companyId
        });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  download({ dispatch, commit }, { query = {}, companyId }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setDownloadOrderRecording', { orderRecordings: [], companyId });

    orderRecordingService
      .list({ query })
      .then(response => {
        commit('setDownloadOrderRecording', { orderRecordings: response.data.rows, companyId });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  },
  updateRecordingStatusFeedback({ dispatch, commit }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });

    orderRecordingService
      .postFeedback({ query })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'bottom-end',
            title: '',
            text: 'Status submitted succesfully!'
          },
          { root: true }
        );
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  finishRequest(state) {
    state.loading = false;
  },
  setOrdersRecordings(state, { orderRecordings, pagination, companyId }) {
    state.orderRecordings = _.map(orderRecordings, recording => {
      const formattedOrders = new Recordings({
        id: recording.id,
        companyId,
        callerId: recording.number,
        storeid: recording.store_id,
        filename: recording.filename,
        regTime: recording.reg_time,
        feedback: recording.feedback,
        managerNumber: recording.manager_number
      });
      return formattedOrders;
    });
    state.pagination = pagination;
    state.loading = false;
  },
  setDownloadOrderRecording(state, { orderRecordings, companyId }) {
    state.downloadOrderRecordings = _.map(orderRecordings, recording => {
      const formattedOrders = new Recordings({
        id: recording.id,
        companyId,
        callerId: recording.number,
        storeid: recording.store_id,
        filename: recording.filename,
        regTime: recording.reg_time,
        managerNumber: recording.manager_number
      });
      return formattedOrders;
    });
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
