/* eslint-disable */
import _ from 'lodash';
import productCataloguesService from '@/services/productCataloguesService';

const state = {
  loadingCatalogues: false,
  productCatalogueList: [],
  selectedCatalogue: null,
  loadingProducts: false,
  productList: [],

  isProductFormOpen: false,
  selectedProduct: null,
  loading: false
};
const actions = {
  listCatalogues({ dispatch, commit }, { router }) {
    // dispatch('alert/clear', {}, { root: true });
    commit('startCataloguesRequest');

    productCataloguesService
      .listCatalogues()
      .then(response => {
        commit('setCatalogueList', { productCatalogueList: response.data.rows });
      })
      .catch(e => {
        commit('cataloguesRequestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  listProducts({ dispatch, commit }, { router, catalogue }) {
    commit('startProductsRequest', { selectedCatalogue: catalogue._id, productList: [] }); // eslint-disable-line

    productCataloguesService
      .listProducts({ catalogue })
      .then(response => {
        commit('setProductsList', { productList: response.data.rows });
      })
      .catch(e => {
        commit('productsRequestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  selectProduct({ commit }, { product }) {
    commit('setProductFormOpen', true);
    commit('setSelectedProduct', product);
  },
  openAddProduct({ commit }) {
    commit('setProductFormOpen', true);
  },
  closeAddProduct({ commit }) {
    commit('setProductFormOpen', false);
  },
  addProduct({ commit, state, dispatch }, { router, params }) {
    productCataloguesService
      .addProduct({ product: params })
      .then(response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'New Product has been added.'
          },
          { root: true }
        );
        commit('setCatalogueList', {
          productCatalogueList: state.productCatalogueList.map(item => {
            if (item._id === response.data._id) {
              return response.data;
            }
            return item;
          })
        });
        commit('setProductsList', {
          productList: [...response.data.products]
        });
        commit('setProductFormOpen', false);
      })
      .catch(e => {
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  editProduct({ dispatch, commit }, { params, productId, catalogueId, router }) {
    dispatch('alert/clear', {}, { root: true });

    productCataloguesService
      .editProduct({ product: params, productId, catalogueId })
      .then(_response => {
        commit('setCatalogueList', {
          productCatalogueList: state.productCatalogueList.map(item => {
            if (item._id === catalogueId) {
              return {
                ...item,
                products: item.products.map(product => {
                  if (product._id === productId) {
                    return params;
                  }
                  return product;
                })
              };
            }
          })
        });
        commit('setProductsList', {
          productList: state.productList.map(item => {
            if (item._id === productId) {
              return params;
            }
            return item;
          })
        });
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'Product has been updated.'
          },
          { root: true }
        );
      })
      .catch(e => {
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  addNewCatalogue({ commit, state, dispatch }, { router, params }) {
    productCataloguesService
      .addCatalogue({ params })
      .then(response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'New Catalogue has been added.'
          },
          { root: true }
        );
        commit('setCatalogueList', { productCatalogueList: [response.data, ...state.productCatalogueList] });
      })
      .catch(e => {
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  deleteProduct({ dispatch, commit }, { catalogueId, productId, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    productCataloguesService
      .deleteProduct({ catalogueId, productId })
      .then(_response => {
        commit('setCatalogueList', {
          productCatalogueList: state.productCatalogueList.map(item => {
            if (item._id === catalogueId) {
              return { ...item, products: item.products.filter(product => product._id !== productId) };
            }
            return item;
          })
        });
        commit('setProductsList', {
          productList: state.productList.filter(product => product._id !== productId)
        });
        // dispatch('listProducts', { router });
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'Product has been deleted.'
          },
          { root: true }
        );
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  editCatalogue({ commit, dispatch }, { router, params, catalogueId }) {
    productCataloguesService
      .editCatalogue({ params, catalogueId })
      .then(response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'Catalogue has been updated.'
          },
          { root: true }
        );
        commit('setCatalogueList', {
          productCatalogueList: state.productCatalogueList.map(item => {
            if (response.data && item._id === response.data._id) {
              return response.data;
            }
            return item;
          })
        });
      })
      .catch(e => {
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  deleteCatalogue({ commit, dispatch }, { router, catalogueId }) {
    dispatch('alert/clear', {}, { root: true });

    productCataloguesService
      .deleteCatalogue({ catalogueId })
      .then(_response => {
        dispatch('listCatalogues', { router });
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'Catalogue has been deleted.'
          },
          { root: true }
        );
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  }
};

// const getters = {
//   pendingTodoList: state => _.filter(state.todo, todo => todo.state === 'pending'),
//   ongoingTodoList: state => _.filter(state.todo, todo => todo.state === 'ongoing'),
//   completedTodoList: state => _.filter(state.todo, todo => todo.state === 'completed'),
//   archivedTodoList: state => _.filter(state.todo, todo => todo.state === 'archived')
// };

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  startCataloguesRequest(state) {
    state.loadingCatalogues = true;
  },
  cataloguesRequestFailed(state) {
    state.loadingCatalogues = false;
  },
  setCatalogueList(state, { productCatalogueList }) {
    state.loadingCatalogues = false;
    state.productCatalogueList = productCatalogueList;
    // state.pagination = pagination;
  },

  startProductsRequest(state, { selectedCatalogue }) {
    state.loadingProducts = true;
    state.selectedCatalogue = selectedCatalogue;
  },
  productsRequestFailed(state) {
    state.loadingProducts = false;
  },
  setProductsList(state, { productList }) {
    state.loadingProducts = false;
    state.productList = productList;
  },
  setProductFormOpen(state, payload) {
    state.isProductFormOpen = payload;
  },
  setSelectedProduct(state, selectedProduct) {
    state.selectedProduct = selectedProduct;
  }
};

export default {
  namespaced: true,
  state,
  // getters,
  actions,
  mutations
};
