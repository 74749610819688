export default class Store {
  static storeType = {
    spencer: 0,
    nature: 1
  };

  static nameStoreType = {
    0: 'Spencer',
    1: 'Nature'
  };

  constructor({ id, index, storeName, storeid, cluster, region, format, pincodes, type, locations, isOnline }) {
    this.id = id || '';
    this.index = index + 1;
    this.storeName = storeName || '';
    this.storeid = storeid || '';
    this.cluster = cluster || '';
    this.region = region || '';
    this.format = format || '';
    this.pincodes = pincodes || '';
    this.type = type;
    this.isOnline = isOnline || false;
    this.expand();
    this.locations = locations || '';
  }

  expand() {
    this.typeName = Store.nameStoreType[this.type];
  }
}
