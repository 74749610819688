<template>
  <footer class="footer mt-4 pt-4 my-md-5 pt-md-5 border-top maindiv">
    <div class="row">
      <div v-if="contactUs()" class="col-12 col-md">
        <img class="mb-2" src="../assets/images/newlogo.png" alt height="60" width="120" />
        <small class="d-block mb-3 text-muted">&copy; {{ getYear() }}</small>
      </div>
    </div>
    <div>
      <!-- <marquee onmouseover="this.stop()" onmouseout="this.start();" behavior="alternate" direction="">
        <a target="_blank" href="https://share-eu1.hsforms.com/1oced_4UORcyQl2R6XUPAQwfdrk1">Contact Us</a>
      </marquee> -->
      <b-button v-if="show()" class="contactButton" target="_blank" @click="goToContactUs()" variant="primary">
        <font-awesome-icon :icon="['fas', 'envelope']" class="mr-1" /> Contact Us
      </b-button>
    </div>
  </footer>
</template>

<script>
import { mapState } from 'vuex';
// import User from '../model/user';

export default {
  name: 'FooterBar',
  computed: {
    ...mapState('auth', ['user'])
  },
  methods: {
    show() {
      if (this.$route.path === '/contactUs') {
        return false;
      }
      if (this.user && this.user.role) {
        return true;
      }
      return false;
    },
    getYear() {
      const date = new Date();
      return date.getFullYear();
    },
    goToContactUs() {
      window.open('/contactUs', '_blank');
    },
    contactUs() {
      if (this.$route.path === '/contactUs') {
        return false;
      }
      if (this.$route.path === '/product') {
        return false;
      }
      return true;
    }
  }
};
</script>

<style scoped>
.maindiv {
  display: flex;
  justify-content: space-between;
}

.contactButton {
  margin-top: 7px;
}
.contactButton:hover {
  opacity: 0.8;
}
</style>
