<template>
  <div class="app-layout app-layout-simple">
    <b-container fluid>
      <slot />
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'SimpleLayout'
};
</script>
