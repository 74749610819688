import _ from 'lodash';
import axios from 'axios';
import configService from '@/services/configService';
import utils from '@/helper/utils';

export default {
  async findProducts({ query = {} } = {}) {
    const pickedQuery = _.pick(query, ['q', 'text', 'storeid']);
    let url = `${configService.get('apiUrl')}/catalogue`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async findProductsOnline({ query = {} } = {}) {
    const pickedQuery = _.pick(query, ['q', 'text', 'storeid']);
    let url = `${configService.get('apiUrl')}/online-catalogue`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  }
};
