import _ from 'lodash';
import targetService from '@/services/targetService';
import Target from '../../model/target';

const state = {
  baseUrl: '/target-upload',
  targetList: [],
  downloadTargetList: [],
  pagination: {},
  loading: false
};

const actions = {
  listTarget({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setTargets', { targets: [], pagination: {} });

    commit('startRequest');

    targetService
      .listTarget({ query })
      .then(response => {
        commit('setTargets', { targets: response.data.rows, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  upload({ dispatch, commit }, { data, type, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    targetService
      .upload({ data, type })
      .then(_response => {
        commit('finishRequest');
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            type: 'success',
            text: 'Data uploaded successfully !!'
          },
          { root: true }
        );
        router.go();
      })
      .catch(e => {
        let errorMessage = '';
        if (e.response.data.data[0]) {
          errorMessage = e.response.data.data[0].msg;
        }
        commit('requestFailed');
        dispatch(
          'alert/error',
          {
            position: 'center',
            title: errorMessage,
            type: 'error',
            showType: 'dialog',
            text: 'Upload failed, remember to use only sample file provided and check the line number !!'
          },
          { root: true }
        );
        dispatch('common/handleServiceException', { e }, { root: true });
      });
    commit('finishRequest');
  },
  async download({ dispatch, commit }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setDownloadTargetList', { downloadTargetList: [] });

    let stopDownload = false;
    const pageSize = 5000;
    const accData = [];

    for (let i = 0; !stopDownload;) {
      try {
        // eslint-disable-next-line no-await-in-loop
        const response = await targetService.listTarget({ query: { ...query, page: i + 1, page_size: pageSize } });
        if (response.data.pagination.total_rows > 5000) {
          dispatch(
            'alert/success',
            {
              showType: 'toast',
              position: 'center',
              title: 'Preparing your download, please wait...'
            },
            { root: true }
          );
        }
        if (response.data.rows.length === 0) {
          stopDownload = true;
        }
        accData.push(...response.data.rows);
        i += 1;
      } catch (e) {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
        return;
      }
    }
    commit('setDownloadTargetList', { downloadTargetList: accData });
  },
  setDownloadListToEmpty({ commit }) {
    commit('setDownloadTargetList', { downloadTargetList: [] });
  },
  deleteOne({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    targetService
      .deleteOne({ id })
      .then(_response => {
        dispatch('listTarget', { id });
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: ' Target has been deleted.'
          },
          { root: true }
        );
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  setTargets(state, { targets, pagination }) {
    let row = 1;
    state.targetList = targets.map(target => {
      const { _id } = target;
      const newTarget = new Target({
        id: _id,
        rowNum: row,
        date: target.date,
        storeid: target.storeid,
        target: target.target,
        targetSales: target.targetSales,
        createdAt: target.createdAt,
        submittedBy: target.submittedBy,
        submittedByName: target.submittedByName
      });
      row += 1;
      return newTarget;
    });
    state.pagination = pagination;
    state.loading = false;
  },
  setDownloadTargetList(state, { downloadTargetList }) {
    let row = 1;
    state.downloadTargetList = _.map(downloadTargetList, target => {
      const { _id } = target;
      const formattedTarget = new Target({
        id: _id,
        rowNum: row,
        date: target.date,
        storeid: target.storeid,
        target: target.target,
        targetSales: target.targetSales,
        createdAt: target.createdAt,
        submittedBy: target.submittedBy,
        submittedByName: target.submittedByName
      });
      row += 1;
      return formattedTarget;
    });
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
