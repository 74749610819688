import callsService from '@/services/callsService';
import _ from 'lodash';
import moment from 'moment';
import Calls from '../../model/calls';

const state = {
  baseUrl: '/calls',
  callsList: [],
  downloadReport: [],
  currentCallsList: localStorage.getItem('currentCallsList')
    ? JSON.parse(localStorage.getItem('currentCallsList'))
    : [],
  downloadCallsList: [],
  pagination: null,
  loading: false
};

const actions = {
  list({ dispatch, commit }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    callsService
      .list({ query })
      .then(response => {
        commit('setCallsList', { callsList: response.data.rows, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  },
  download({ dispatch, commit }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setDownloadCallsList', { downloadCallsList: [] });

    callsService
      .list({ query })
      .then(response => {
        commit('setDownloadCallsList', { downloadCallsList: response.data.rows });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  },
  updateCallStatusFeedback({ dispatch, commit }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });

    callsService
      .postFeedback({ query })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'Status submitted succesfully!'
          },
          { root: true }
        );
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  },
  updateCurrentCallList({ commit }, { data, storeid }) {
    commit('setCurrentCallsList', { currentCall: data, storeid });
  },
  scheduleUpdateCurrentCallsList({ commit }) {
    commit('scheduleUpdateCurrentCallsList');
  },
  setDownloadListToEmpty({ commit }) {
    commit('setDownloadCallsList', { downloadCallsList: [] });
  },
  clear({ commit }) {
    commit('clear');
  },
  report({ dispatch, commit }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setDownloadReport', { downloadReport: [] });

    callsService
      .report({ query })
      .then(response => {
        commit('setDownloadReport', { downloadReport: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  },
  setDownloadReportToEmpty({ commit }) {
    commit('setDownloadReport', { downloadReport: [] });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  setCallsList(state, { callsList, pagination }) {
    state.callsList = _.map(callsList, call => {
      const { clid } = call;
      const callerId = clid.match(/"(.*?)"/)[1].length < 10 ? clid.match(/<(.*?)>/)[1] : clid.match(/"(.*?)"/)[1];
      const formatCalls = new Calls({
        id: call.id,
        callerId,
        feedback: call.feedback,
        storeid: call.store_id,
        disposition: call.disposition,
        duration: call.billsec,
        datetime: call.calldate,
        updatedAt: call.updatedAt
      });
      return formatCalls;
    });
    state.pagination = pagination;
    state.loading = false;
  },
  setDownloadCallsList(state, { downloadCallsList }) {
    state.downloadCallsList = _.map(downloadCallsList, call => {
      const { clid } = call;
      const callerId = clid.match(/"(.*?)"/)[1].length < 10 ? clid.match(/<(.*?)>/)[1] : clid.match(/"(.*?)"/)[1];
      const formatCalls = new Calls({
        id: call.id,
        callerId,
        storeid: call.store_id,
        feedback: call.feedback,
        disposition: call.disposition,
        duration: call.billsec,
        datetime: call.calldate
      });
      return formatCalls;
    });
    state.loading = false;
  },
  clear(state) {
    state.callsList = [];
    state.currentCallsList = [];
    localStorage.removeItem('currentCallsList');
  },
  setCurrentCallsList(state, { currentCall, storeid }) {
    const { customerMobile } = currentCall;
    let callsList = state.currentCallsList;
    if (customerMobile.length > 9) {
      const call = new Calls({
        id: null,
        callerId: customerMobile,
        storeid,
        date: moment().format('LLL')
      });
      if (callsList.length > 9) {
        callsList.pop();
      }
      callsList.unshift(call);
      callsList = callsList.map((call, index) => new Calls({ ...call, id: index + 1 }));
      state.currentCallsList = callsList;
      localStorage.setItem('currentCallsList', JSON.stringify(callsList));
    }
    state.loading = false;
  },
  scheduleUpdateCurrentCallsList(state) {
    state.currentCallsList = state.currentCallsList.filter(current => {
      if (moment().diff(moment(current.datetime), 'minutes') < 10) {
        return current;
      }
      return null;
    });
    state.loading = false;
  },
  setDownloadReport(state, { downloadReport }) {
    state.downloadReport = downloadReport;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
