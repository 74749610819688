// import _ from 'lodash';
import B2BReportService from '../../services/B2BReportService';
import B2BReport from '../../model/B2BReport';

const state = {
  baseUrl: '/bTobReport',
  reportList: [],
  pagination: {},
  b2bStoreList: [],
  loading: false,
  downloadReport: []
};

const actions = {
  list({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setReportList', { reportList: [], pagination: null });
    commit('startRequest');
    B2BReportService.list({ query })
      .then(response => {
        commit('setReportList', { reportList: response.data, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  b2BStoreList({ dispatch, commit }, { router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setB2bStoreList', { b2bStoreList: [], pagination: null });
    commit('startRequest');
    B2BReportService.b2BStoreList()
      .then(response => {
        commit('setB2bStoreList', { b2bStoreList: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },

  download({ dispatch, commit }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setDownloadReport', { downloadReport: [] });
    commit('startRequest');

    B2BReportService.list({ query })
      .then(response => {
        commit('setDownloadReport', { downloadReport: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  },

  setDownloadListToEmpty({ commit }) {
    commit('setDownloadReport', { downloadReport: [] });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  finishRequest(state) {
    state.loading = false;
  },
  setB2bStoreList(state, { b2bStoreList }) {
    state.b2bStoreList = b2bStoreList;
  },
  setDownloadReport(state, { downloadReport }) {
    state.downloadReport = downloadReport
      .sort((a, b) => {
        const nameA = a.bdaName.toUpperCase(); // ignore upper and lowercase
        const nameB = b.bdaName.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      })
      .map((report, index) => {
        const formattedData = new B2BReport({
          index,
          _id: report._id, // eslint-disable-line
          bdaName: report.bdaName || '',
          storeId: report.storeId || '',
          totalOrderCreated: report.totalOrderCreated || '',
          totalItemCount: report.totalItemCount || '',
          sumOfQuantity: report.sumOfQuantity || '',
          sumOfCartValue: report.sumOfCartValue || '',
          totalChallanGenerated: report.totalChallanGenerated || '',
          totalItemBilled: report.totalItemBilled || '',
          sumOfQuantityBilled: report.sumOfQuantityBilled || '',
          sumOfChallanValue: report.sumOfChallanValue || ''
        });
        return formattedData;
      });
    let grandTotal = {};
    if (downloadReport.length > 0) {
      grandTotal = downloadReport.reduce((a, b) => ({
        index: '',
        bdaName: 'Grand Total',
        storeId: '',
        totalOrderCreated: a.totalOrderCreated + b.totalOrderCreated,
        totalItemCount: a.totalItemCount + b.totalItemCount,
        sumOfQuantity: a.sumOfQuantity + b.sumOfQuantity,
        sumOfCartValue: (Number(a.sumOfCartValue) + Number(b.sumOfCartValue)).toFixed(2),
        totalChallanGenerated: a.totalChallanGenerated + b.totalChallanGenerated,
        totalItemBilled: a.totalItemBilled + b.totalItemBilled,
        sumOfQuantityBilled: (Number(a.sumOfQuantityBilled) + Number(b.sumOfQuantityBilled)).toFixed(2),
        sumOfChallanValue: (Number(a.sumOfChallanValue) + Number(b.sumOfChallanValue)).toFixed(2)
      }));
      downloadReport.push(grandTotal);
    }

    state.downloadReport = downloadReport;
    state.loading = false;
  },
  setReportList(state, { reportList, pagination }) {
    const formattedList = reportList
      .sort((a, b) => {
        const nameA = a.bdaName.toUpperCase(); // ignore upper and lowercase
        const nameB = b.bdaName.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      })
      .map((report, index) => {
        const formattedData = new B2BReport({
          index,
          _id: report._id, // eslint-disable-line
          bdaName: report.bdaName || '',
          storeId: report.storeId || '',
          totalOrderCreated: report.totalOrderCreated || '',
          totalItemCount: report.totalItemCount || '',
          sumOfQuantity: report.sumOfQuantity || '',
          sumOfCartValue: report.sumOfCartValue || '',
          totalChallanGenerated: report.totalChallanGenerated || '',
          totalItemBilled: report.totalItemBilled || '',
          sumOfQuantityBilled: report.sumOfQuantityBilled || '',
          sumOfChallanValue: report.sumOfChallanValue || ''
        });
        return formattedData;
      });

    let grandTotal = {};
    if (formattedList.length > 0) {
      grandTotal = formattedList.reduce((a, b) => ({
        bdaName: 'Grand Total',
        totalOrderCreated: a.totalOrderCreated + b.totalOrderCreated,
        totalItemCount: a.totalItemCount + b.totalItemCount,
        sumOfQuantity: a.sumOfQuantity + b.sumOfQuantity,
        sumOfCartValue: (Number(a.sumOfCartValue) + Number(b.sumOfCartValue)).toFixed(2),
        totalChallanGenerated: a.totalChallanGenerated + b.totalChallanGenerated,
        totalItemBilled: a.totalItemBilled + b.totalItemBilled,
        sumOfQuantityBilled: (Number(a.sumOfQuantityBilled) + Number(b.sumOfQuantityBilled)).toFixed(2),
        sumOfChallanValue: (Number(a.sumOfChallanValue) + Number(b.sumOfChallanValue)).toFixed(2)
      }));
      formattedList.push(grandTotal);
    }
    state.reportList = formattedList;
    state.pagination = pagination;
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
