import moment from 'moment';

export default class User {
  static userEnabled = {
    active: 1,
    disabled: 0
  };

  static userRole = {
    administrator: 99,
    staff: 50,
    caller: 25,
    user: 1
  };

  static nameUserStatus = {
    1: 'Active',
    0: 'Not Active'
  };

  static nameUserRole = {
    99: 'Administrator',
    50: 'Store Staff',
    25: 'Outbound Caller',
    1: 'User'
  };

  static usersType = {
    spencer: 0,
    nature: 1
  };

  static nameUserType = {
    0: 'Spencer',
    1: 'Nature'
  };

  constructor({
    rowNum,
    id,
    name,
    mobile,
    role,
    roles,
    status,
    type,
    empid,
    designation,
    storeid,
    storeids,
    clusters,
    regions,
    lastLogin,
    pincode,
    nearbyPincode,
    storeManagerNumber,
    smsNumber
  }) {
    this.rowNum = rowNum || null;
    this.id = id;
    this.name = name;
    this.mobile = mobile;
    this.role = role;
    this.status = status;
    this.empid = empid || 'NA';
    this.lastLogin = lastLogin ? moment(lastLogin).format('LLL') : null;
    this.designation = designation || 'NA';
    this.storeid = storeid || null;
    this.storeids = storeids || [];
    this.clusters = clusters || [];
    this.regions = regions || [];
    this.pincode = pincode || 'NA';
    this.nearbyPincode = nearbyPincode || 'NA';
    this.storeManagerNumber = storeManagerNumber || 'NA';
    this.smsNumber = smsNumber || 'NA';
    this.type = type;
    this.roles = roles || [];
    this.expand();
  }

  expand() {
    this.roleName = this.roles?.map(e => e.name).join(', ');
    this.statusName = User.nameUserStatus[this.status];
    this.typeName = User.nameUserType[this.type];
  }
}
