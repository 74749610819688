import moment from "moment";

export default class Calls {
  constructor({
    id,
    callerId,
    disposition,
    storeid,
    datetime,
    duration,
    feedback,
    updatedAt
  }) {
    this.id = id || '1';
    this.callerId = callerId;
    this.disposition = disposition || null;
    this.storeid = storeid || null;
    this.datetime = moment(datetime).format('LLL');
    this.duration = duration || null;
    this.feedback = feedback;
    this.updatedAt = moment(updatedAt).format('LLL');
  }
}
