import _ from 'lodash';
import catalogueService from '@/services/catalogueService';
import Catalogue from '../../model/catalogue';

const state = {
  baseUrl: '/order',
  productsList: []
};

const actions = {
  findProducts({ dispatch, commit }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setProductsList', { productsList: [] });

    catalogueService
      .findProducts({ query })
      .then(response => {
        commit('setProductsList', { productsList: response.data });
        commit('finishRequest');
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  },
  findProductsOnline({ dispatch, commit }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setProductsListOnline', { productsList: [] });

    catalogueService
      .findProductsOnline({ query })
      .then(response => {
        commit('setProductsListOnline', { productsList: response.data });
        commit('finishRequest');
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  finishRequest(state) {
    state.loading = false;
  },
  setProductsList(state, { productsList }) {
    state.productsList = _.map(productsList, product => {
      const { _id } = product;
      const formattedList = new Catalogue({
        id: _id,
        productName: product.productName,
        price: product.price,
        articleCode: product.articleId.slice(-7),
        mrp: product.mrp,
        scale2Price: product.scale2Price,
        scale2Qty: product.scale2Qty,
        scale3Price: product.scale3Price,
        scale3Qty: product.scale3Qty
      });
      return formattedList;
    });
    state.loading = false;
  },
  setProductsListOnline(state, { productsList }) {
    state.productsList = _.map(productsList, product => {
      const { _id } = product;
      const formattedList = new Catalogue({
        id: _id,
        productName: product.productName,
        price: product.price,
        articleCode: product.articleId,
        mrp: product.mrp,
        scale2Price: product.scale2Price,
        scale2Qty: product.scale2Qty,
        scale3Price: product.scale3Price,
        scale3Qty: product.scale3Qty
      });
      return formattedList;
    });
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
