import _ from 'lodash';
import pdOfferService from '@/services/pdOfferService';
import Offers from '../../model/pdoffer';

const state = {
  baseUrl: '/offer',
  pagination: {},
  offers: [],
  loading: false,
  offer: null
};

const actions = {
  listOffers({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setOffers', { offers: [], pagination: {} });

    commit('startRequest');

    pdOfferService
      .listOffers({ query })
      .then(response => {
        commit('setOffers', { offers: response.data.rows, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  postOne({ dispatch, commit }, { offer, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    pdOfferService
      .postOne({ offer })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'New Offer has been added.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  getOne({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setOffer', { offer: {} });

    pdOfferService
      .getOne({ id })
      .then(response => {
        commit('setOffer', { offer: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  patchOne({ dispatch, commit }, { id, offer, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    pdOfferService
      .patchOne({ id, newOffer: offer })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'Offer has been updated.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  deleteOne({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    pdOfferService
      .deleteOne({ id })
      .then(_response => {
        dispatch('listOffers', { id });
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: ' Offer has been deleted.'
          },
          { root: true }
        );
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  setOffers(state, { offers, pagination }) {
    state.offers = _.map(offers, (offer, index) => {
      const newOffers = new Offers({
        index,
        id: offer._id, // eslint-disable-line
        name: offer.name,
        active: offer.active,
        startDate: offer.startDate,
        endDate: offer.endDate,
        storeids: offer.storeids ? offer.storeids.join(',') : '',
        clusters: offer.clusters ? offer.clusters.join(',') : '',
        regions: offer.regions ? offer.regions.join(',') : '',
        formats: offer.formats ? offer.formats.join(',') : ''
      });
      return newOffers;
    });
    state.pagination = pagination;
    state.loading = false;
  },
  setOffer(state, { offer }) {
    state.offer = new Offers({
      id: offer._id, // eslint-disable-line
      name: offer.name,
      active: offer.active,
      startDate: offer.startDate,
      endDate: offer.endDate,
      storeids: offer.storeids,
      clusters: offer.clusters ? offer.clusters : [],
      regions: offer.regions ? offer.regions : [],
      formats: offer.formats ? offer.formats : []
    });
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
