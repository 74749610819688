import moment from "moment";

export default class Calendar {
  constructor({
    id,
    rowNum,
    region,
    campaignName,
    campaignStartDate,
    campaignEndDate,
    catalogueCreationBy,
    catalogueApprovalBy,
    catalogueLiveBy,
    createdAt
  }) {
    this.id = id || '';
    this.rowNum = rowNum || '';
    this.region = region || '';
    this.campaignName = campaignName || '';
    this.campaignStartDate = moment(campaignStartDate).format('DD-MMM-YYYY');
    this.campaignEndDate = moment(campaignEndDate).format('DD-MMM-YYYY');
    this.catalogueCreationBy = moment(catalogueCreationBy).format('DD-MMM-YYYY');
    this.catalogueApprovalBy = moment(catalogueApprovalBy).format('DD-MMM-YYYY');
    this.catalogueLiveBy = moment(catalogueLiveBy).format('DD-MMM-YYYY');
    this.createdAt = moment(createdAt).format('DD-MMM-YYYY');
  }
}
