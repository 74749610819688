export default class InboundCaller {

  constructor({
    row,
    mobile,
    storeId,
    name,
    empId
  }) {
    this.row = row;
    this.mobile = mobile || null;
    this.storeId = storeId;
    this.name = name;
    this.empId = empId;
  }
}

