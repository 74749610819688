import _ from 'lodash';
import roleService from '../../services/roleService';
import Role from '../../model/role';

const state = {
  baseUrl: '/role',
  roles: [],
  pagination: {},
  loading: false,
  role: null,
  rolesList: []
};

const actions = {
  listRole({ dispatch, commit }, { router }) {
    commit('startRequest');
    dispatch('alert/clear', {}, { root: true });

    roleService
      .listRole({ router })
      .then(response => {
        commit('setRoles', { roles: response.data });
        commit('setRolesList', { rolesList: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  getOne({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setRole', { role: {} });
    roleService
      .getOne({ id })
      .then(response => {
        commit('setRole', { role: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  postOne({ dispatch, commit }, { role, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    roleService
      .postOne({ role })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'New Role has been added.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  patchOne({ dispatch, commit }, { id, role, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    roleService
      .patchOne({ id, role })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'Role has been updated.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  setRoles(state, { roles }) {
    if (roles) {
      const role = roles.map(({ name, _id }) => ({ text: name, value: _id }));
      state.roles = role;
    }
    state.loading = false;
  },
  setRolesList(state, { rolesList }) {
    state.rolesList = _.map(rolesList, (role, index) => {
      const newRole = new Role({
        index,
        id: role._id, // eslint-disable-line
        name: role.name,
        description: role.description,
        permissions: role.permissions.map(permissions => permissions.name).join(',')
      });

      return newRole;
    });

    state.loading = false;
  },

  setRole(state, { role }) {
    state.role = new Role({
      id: role._id, // eslint-disable-line
      name: role.name,
      description: role.description,
      permissions: role.permissions
    });

    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
