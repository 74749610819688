import _ from 'lodash';
import axios from 'axios';
import configService from '@/services/configService';
import utils from '@/helper/utils';

export default {
  async listOutboundCaller({ query = {} } = {}) {
    const pickedQuery = _.pick(query, [
      'q',
      'page',
      'page_size',
      'search',
      'searchkey',
      'startdate',
      'enddate',
      'filter'
    ]);
    let url = `${configService.get('apiUrl')}/ouboundCallerList`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },

  async getNumberToDelete({ query = {} } = {}) {
    const pickedQuery = _.pick(query, ['startdate', 'enddate', 'filter', 'storeid']);
    let url = `${configService.get('apiUrl')}/outbound-caller-delete`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }
    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async deleteNumbers({ query = {} } = {}) {
    const pickedQuery = _.pick(query, ['startdate', 'enddate', 'filter', 'storeid']);
    let url = `${configService.get('apiUrl')}/outbound-caller-delete`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }
    return axios
      .delete(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },

  async dialedList({ query = {} } = {}) {
    const pickedQuery = _.pick(query, [
      'q',
      'page',
      'page_size',
      'search',
      'searchkey',
      'startdate',
      'enddate',
      'filter'
    ]);
    let url = `${configService.get('apiUrl')}/dialedCallList`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async callingReport({ query = {} } = {}) {
    const pickedQuery = _.pick(query, [
      'q',
      'page',
      'page_size',
      'search',
      'searchkey',
      'startdate',
      'enddate',
      'filter'
    ]);
    let url = `${configService.get('apiUrl')}/outboundCallerReportPrevious`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async teleCallerReport({ query = {} } = {}) {
    const pickedQuery = _.pick(query, [
      'q',
      'page',
      'page_size',
      'search',
      'searchkey',
      'startdate',
      'enddate',
      'filter'
    ]);
    let url = `${configService.get('apiUrl')}/telecallerReport`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }
    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async outboundCallerReportDaywise({ query = {} } = {}) {
    const pickedQuery = _.pick(query, [
      'q',
      'page',
      'page_size',
      'search',
      'searchkey',
      'startdate',
      'enddate',
      'filter'
    ]);
    let url = `${configService.get('apiUrl')}/outboundCallerReportDaywise`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }
    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async teleCallerReportDaywise({ query = {} } = {}) {
    const pickedQuery = _.pick(query, [
      'q',
      'page',
      'page_size',
      'search',
      'searchkey',
      'startdate',
      'enddate',
      'filter'
    ]);
    let url = `${configService.get('apiUrl')}/telecallerReportDaywise`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }
    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async callerReportLive({ query = {} } = {}) {
    const pickedQuery = _.pick(query, [
      'q',
      'page',
      'page_size',
      'search',
      'searchkey',
      'startdate',
      'enddate',
      'filter'
    ]);
    let url = `${configService.get('apiUrl')}/outboundCallerReport`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }
    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },

  async upload({ data } = {}) {
    return axios
      .post(`${configService.get('apiUrl')}/outboundCallerList/upload`, { outboundCallers: data })
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async uploadBlacklist({ data } = {}) {
    return axios
      .post(`${configService.get('apiUrl')}/outboundBlocked/upload`, { outboundBlockedList: data })
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async blockedNumberList({ query = {} } = {}) {
    const pickedQuery = _.pick(query, [
      'q',
      'page',
      'page_size',
      'search',
      'searchkey',
      'startdate',
      'enddate',
      'filter'
    ]);
    let url = `${configService.get('apiUrl')}/outboundBlocked`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async deleteOne({ id }) {
    return axios
      .delete(`${configService.get('apiUrl')}/outboundBlocked/${id}`)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async removeFromCallingList() {
    return axios
      .delete(`${configService.get('apiUrl')}/outboundBlocked/removeFromCallerList`)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  }
};
