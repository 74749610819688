import _ from 'lodash';
import calendarService from '@/services/calendarService';
import Calendar from '../../model/calendar';

const state = {
  baseUrl: '/productCalendar',
  calendarList: [],
  downloadCalendarList: [],
  pagination: {},
  loading: false
};

const actions = {
  listCalendar({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setCalendars', { calendars: [], pagination: {} });

    commit('startRequest');

    calendarService
      .listCalendar({ query })
      .then(response => {
        commit('setCalendars', { calendars: response.data.rows, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  upload({ dispatch, commit }, { data, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    calendarService
      .upload({ data })
      .then(_response => {
        commit('finishRequest');
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            type: 'success',
            text: 'Campaign Calendar uploaded successfully !!'
          },
          { root: true }
        );
        router.go();
      })
      .catch(e => {
        let errorMessage = '';
        if (e.response.data.data[0]) {
          errorMessage = e.response.data.data[0].msg;
        }
        commit('requestFailed');
        dispatch(
          'alert/error',
          {
            position: 'center',
            title: errorMessage,
            type: 'error',
            showType: 'dialog',
            text: 'Upload failed, remember to use only sample file provided and check the line number !!'
          },
          { root: true }
        );
        dispatch('common/handleServiceException', { e }, { root: true });
      });
    commit('finishRequest');
  },
  download({ dispatch, commit }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setDownloadCalendarList', { downloadCalendarList: [] });

    calendarService
      .listCalendar({ query })
      .then(response => {
        commit('setDownloadCalendarList', {
          downloadCalendarList: response.data.rows
        });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch(
          'common/handleServiceException',
          { e },
          {
            root: true
          }
        );
      });
  },
  setDownloadListToEmpty({ commit }) {
    commit('setDownloadCalendarList', { downloadCalendarList: [] });
  },
  deleteOne({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    calendarService
      .deleteOne({ id })
      .then(_response => {
        dispatch('listCalendar', { id });
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: ' Campaign has been deleted.'
          },
          { root: true }
        );
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  setCalendars(state, { calendars, pagination }) {
    let row = 1;
    state.calendarList = calendars.map(calendar => {
      const { _id } = calendar;
      const newCalendar = new Calendar({
        id: _id,
        rowNum: row,
        region: calendar.region,
        campaignName: calendar.campaignName,
        campaignStartDate: calendar.campaignStartDate,
        campaignEndDate: calendar.campaignEndDate,
        catalogueCreationBy: calendar.catalogueCreationBy,
        catalogueApprovalBy: calendar.catalogueApprovalBy,
        catalogueLiveBy: calendar.catalogueLiveBy,
        createdAt: calendar.createdAt
      });
      row += 1;
      return newCalendar;
    });
    state.pagination = pagination;
    state.loading = false;
  },
  setDownloadCalendarList(state, { downloadCalendarList }) {
    let row = 1;
    state.downloadCalendarList = _.map(downloadCalendarList, calendar => {
      const { _id } = calendar;
      const formattedCalendar = new Calendar({
        id: _id,
        rowNum: row,
        region: calendar.region,
        campaignName: calendar.campaignName,
        campaignStartDate: calendar.campaignStartDate,
        campaignEndDate: calendar.campaignEndDate,
        catalogueCreationBy: calendar.catalogueCreationBy,
        catalogueApprovalBy: calendar.catalogueApprovalBy,
        catalogueLiveBy: calendar.catalogueLiveBy,
        createdAt: calendar.createdAt
      });
      row += 1;
      return formattedCalendar;
    });
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
