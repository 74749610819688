import moment from 'moment';

export default class B2BOrder {
  constructor({
    id,
    orderId,
    salesAssociateName,
    customerName,
    source,
    products,
    storeId,
    createdAt,
    gstNumber,
    employeeId,
    address,
    rowNum,
    description,
    customerMobile,
    dateCreated,
    dateUpdated,
    isAcknowledged,
    isDelivered,
    acknowledgedTime,
    packagedTime,
    posPingTime,
    posItems,
    modeOfPayment,
    pinCode,
    billNumber,
    orderMode,
    deliveryCode,
    actualDeliveredTime,
    imageURL,
    deliveryPics,
    quantity,
    articleCode,
    mrp,
    sellingPrice,
    isPrinted,
    sellQuantity
  }) {
    this.id = id;
    this.storeId = storeId || null;
    this.salesAssociateName = salesAssociateName;
    this.gstNumber = gstNumber || '';
    this.employeeId = employeeId || '';
    this.products = products ? products.join(',') : null;
    this.quantity = quantity ? quantity.join(',') : null;
    this.articleCode = articleCode ? articleCode.join(',') : null;
    this.mrp = mrp ? mrp.join(',') : null;
    this.sellingPrice = sellingPrice ? sellingPrice.join(',') : null;
    this.address = address || '';
    this.isAcknowledged = isAcknowledged || false;
    this.isPrinted = isPrinted || false;
    this.source = source || 'SINGLE_FORM_ENTRY';
    this.isDelivered = isDelivered || false;
    this.rowNum = rowNum + 1;
    this.orderId = orderId;
    this.acknowledgedTime = acknowledgedTime ? moment(acknowledgedTime).format('DD-MM-YYYY hh:mm  A') : null;
    this.customerName = customerName || '';
    this.description = description || '';
    this.dateUpdated = moment(dateUpdated).format('DD-MM-YYYY hh:mm  A');
    this.customerMobile = customerMobile || '';
    this.dateCreated = moment(dateCreated).format('DD-MM-YYYY hh:mm  A');
    this.createdAt = createdAt ? moment(createdAt).format('DD-MM-YYYY hh:mm A') : null;
    this.acknowledgedTime = acknowledgedTime ? moment(acknowledgedTime).format('DD-MM-YYYY hh:mm  A') : null;
    this.packagedTime = packagedTime ? moment(packagedTime).format('DD-MM-YYYY hh:mm  A') : null;
    this.posPingTime = posPingTime ? moment(posPingTime).format('DD-MM-YYYY hh:mm  A') : null;
    this.actualDeliveredTime = actualDeliveredTime ? moment(actualDeliveredTime).format('DD-MM-YYYY hh:mm A') : null;
    this.orderMode = orderMode || '';
    this.pinCode = pinCode || '';
    this.posItems = posItems || [];
    this.imageURL = imageURL || '';
    this.deliveryPics = deliveryPics || '';
    this.modeOfPayment = modeOfPayment || '';
    this.billNumber = billNumber || '';
    this.deliveryCode = deliveryCode || '-';
    this.sellQuantity=sellQuantity||[];
  }
}
