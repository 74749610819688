// import _ from 'lodash';
import categoryReportService from '../../services/categoryReportService';
// import CategoryReport from '../../model/categoryReport';

const state = {
  baseUrl: '/sales/category-wise',
  categoryListNob: [],
  categoryListSale: [],
  loading: false,
  downloadReport: []
};

const actions = {
  listNob({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    categoryReportService
      .listNob({ query })
      .then(response => {
        commit('setCategoryListNob', { categoryList: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  listSale({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    categoryReportService
      .listSale({ query })
      .then(response => {
        commit('setCategoryListSale', { categoryList: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  download({ dispatch, commit }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setDownloadReport', { downloadReport: [] });
    commit('startRequest');

    categoryReportService
      .list({ query })
      .then(response => {
        commit('setDownloadReport', { downloadReport: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  },
  setDownloadListToEmpty({ commit }) {
    commit('setDownloadReport', { downloadReport: [] });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  finishRequest(state) {
    state.loading = false;
  },
  setCategoryListNob(state, { categoryList }) {
    let modifiedCategoryList = [];
    ['East', 'North', 'South', 'West'].forEach(region => {
      const regionData = categoryList.lastDay.filter(({ _id }) => _id === region);
      if (regionData.length > 0) {
        Object.entries(regionData[0]).forEach(([key, value]) => {
          if (key !== '_id') {
            const indexOfCategory = modifiedCategoryList.findIndex(item => item.category === key);
            if (indexOfCategory > -1) {
              modifiedCategoryList[indexOfCategory] = {
                ...modifiedCategoryList[indexOfCategory],
                [`${region}LD`]: value
              };
            } else {
              modifiedCategoryList.push({
                category: key,
                [`${region}LD`]: value
              });
            }
          }
        });
      }

      const regionDataMonth = categoryList.monthTillDate.filter(({ _id }) => _id === region);
      if (regionDataMonth.length > 0) {
        Object.entries(regionDataMonth[0]).forEach(([key, value]) => {
          if (key !== '_id') {
            const indexOfCategory = modifiedCategoryList.findIndex(item => item.category === key);
            if (indexOfCategory > -1) {
              modifiedCategoryList[indexOfCategory] = {
                ...modifiedCategoryList[indexOfCategory],
                [`${region}MTD`]: value
              };
            } else {
              modifiedCategoryList.push({
                category: key,
                [`${region}MTD`]: value
              });
            }
          }
        });
      }
    });
    modifiedCategoryList = modifiedCategoryList.map(cat => ({
      ...cat,
      totalLD: cat.EastLD + cat.NorthLD + cat.SouthLD,
      totalMTD: cat.EastMTD + cat.NorthMTD + cat.SouthMTD
    }));
    modifiedCategoryList = modifiedCategoryList.filter(({ category }) => category !== null);
    state.categoryListNob = modifiedCategoryList;
    state.loading = false;
  },
  setCategoryListSale(state, { categoryList }) {
    let modifiedCategoryList = [];
    const { totalOfCategoryInAllRegionLastDay, totalOfCategoryInAllRegionLastMonth } = categoryList;
    categoryList.lastDay.forEach(({ _id: id, categories }) => {
      categories.forEach(({ category, sale }) => {
        const indexOfCategory = modifiedCategoryList.findIndex(item => item.category === category);
        if (indexOfCategory > -1) {
          modifiedCategoryList[indexOfCategory] = {
            ...modifiedCategoryList[indexOfCategory],
            [`${id}LD`]: sale.toFixed(2)
          };
        } else {
          modifiedCategoryList.push({
            category,
            [`${id}LD`]: sale.toFixed(2),
            totalLD: totalOfCategoryInAllRegionLastDay
              .filter(({ _id: id }) => id === category)[0]
              .totalCategoryCountInRegion.toFixed(2)
          });
        }
      });
    });
    categoryList.monthTillDate.forEach(({ _id: id, categories }) => {
      categories.forEach(({ category, sale }) => {
        const indexOfCategory = modifiedCategoryList.findIndex(item => item.category === category);
        if (indexOfCategory > -1) {
          modifiedCategoryList[indexOfCategory] = {
            ...modifiedCategoryList[indexOfCategory],
            [`${id}MTD`]: sale.toFixed(2),
            totalMTD: totalOfCategoryInAllRegionLastMonth
              .filter(({ _id: id }) => id === category)[0]
              .totalCategoryCountInRegion.toFixed(2)
          };
        } else {
          modifiedCategoryList.push({
            category,
            [`${id}MTD`]: sale.toFixed(2),
            totalMTD: totalOfCategoryInAllRegionLastMonth
              .filter(({ _id: id }) => id === category)[0]
              .totalCategoryCountInRegion.toFixed(2)
          });
        }
      });
    });
    modifiedCategoryList = modifiedCategoryList.filter(({ category }) => category !== null);
    state.categoryListSale = modifiedCategoryList;
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
