import moment from "moment";

export default class MessageRecords {
  constructor({
    id,
    type,
    createdAt,
    isDelivered,
    medium,
    mobile,
    referenceId,
    storeid
  }) {
    this.id = id || '1';
    this.mobile = mobile || null;
    this.medium = medium || null;
    this.tyep = type || null;
    this.isDelivered = isDelivered || null;
    this.storeid = storeid || null;
    this.referenceId = referenceId || null;
    this.createdAt = moment(createdAt).format('LLL');
  }
}
