import store from '@/store';
import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router({
  base: process.env.BASE_URL || '/',
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'analytics',
      component: () => import(/* webpackChunkName: "analytics" */ '../views/Analytics/AnalyticsList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/calls',
      name: 'calls',
      component: () => import(/* webpackChunkName: "analytics" */ '../views/Calls/CallsList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/logs',
      name: 'logs',
      component: () => import(/* webpackChunkName: "analytics" */ '../views/CallLogs/CallLogs.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/mis',
      name: 'mis',
      component: () => import(/* webpackChunkName: "analytics" */ '../views/MIS/MISLayout.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/mis-table',
      name: 'mis-table',
      component: () => import(/* webpackChunkName: "analytics" */ '../views/MIS/MisTable/MisTable.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    // {
    //   path: '/outbound-log',
    //   name: 'outbound',
    //   component: () => import(/* webpackChunkName: "analytics" */ '../views/OutboundLog/OutboundLogList.vue'),
    //   meta: {
    //     layout: 'backend-layout',
    //     requiresAuth: true
    //   }
    // },
    {
      path: '/login',
      name: 'login',
      component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
      meta: {
        layout: 'simple-layout'
      }
    },
    {
      path: '/change-password',
      name: 'change-password',
      component: () => import(/* webpackChunkName: "login" */ '../views/ChangePassword.vue'),
      meta: {
        layout: 'simple-layout'
      }
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: () => import(/* webpackChunkName: "login" */ '../views/ResetPassword.vue'),
      meta: {
        layout: 'simple-layout'
      }
    },
    // {
    //   path: '/todo',
    //   name: 'todo',
    //   component: () => import(/* webpackChunkName: "todo" */ '../views/Todo/Todo.vue'),
    //   meta: {
    //     layout: 'backend-layout',
    //     requiresAuth: true
    //   }
    // },

    {
      path: '/logout',
      name: 'logout',
      // route level code-splitting
      // this generates a separate chunk (login.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "login" */ '../views/Logout.vue'),
      meta: {
        layout: 'simple-layout'
      }
    },
    {
      path: '/user',
      name: 'user',
      component: () => import(/* webpackChunkName: "user" */ '../views/User/UserList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/permissions',
      name: 'permissions',
      component: () => import(/* webpackChunkName: "user" */ '../views/Permissions/PermissionsList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/user/new',
      name: 'user-new',
      component: () => import(/* webpackChunkName: "user" */ '../views/User/UserForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/user/:id',
      name: 'user-edit',
      component: () => import(/* webpackChunkName: "user" */ '../views/User/UserForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/staff',
      name: 'staff',
      component: () => import(/* webpackChunkName: "staff" */ '../views/Staff/StaffList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/staff/new',
      name: 'staff-new',
      component: () => import(/* webpackChunkName: "staff" */ '../views/Staff/StaffForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/permissions/new',
      name: 'permissions-new',
      component: () => import(/* webpackChunkName: "user" */ '../views/Permissions/PermissionsForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/permissions/:id',
      name: 'permissions-edit',
      component: () => import(/* webpackChunkName: "user" */ '../views/Permissions/PermissionsForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/staff/:id',
      name: 'staff-edit',
      component: () => import(/* webpackChunkName: "staff" */ '../views/Staff/StaffForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    // {
    //   path: '/product-catalogue/:id',
    //   name: 'product-catalogue',
    //   component: () => import(/* webpackChunkName: "staff" */ '../views/Retail/RetailList.vue'),
    //   meta: {
    //     layout: 'backend-layout',
    //     requiresAuth: false
    //   }
    // },
    // {
    //   path: '/product-cart/:id',
    //   name: 'product-cart',
    //   component: () => import(/* webpackChunkName: "staff" */ '../views/Retail/RetailCart.vue'),
    //   meta: {
    //     layout: 'backend-layout',
    //     requiresAuth: false
    //   }
    // },
    {
      path: '/outboundCallerList',
      name: 'outboundCallerList',
      component: () => import(/* webpackChunkName: "staff" */ '../views/OutboundCaller/OutboundCallerList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/dialedCallList',
      name: 'dialedCallList',
      component: () => import(/* webpackChunkName: "staff" */ '../views/OutboundCaller/DialedCallList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/blockedNumberList',
      name: 'blockedNumberList',
      component: () => import(/* webpackChunkName: "staff" */ '../views/OutboundCaller/BlockedList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/searchProduct',
      name: 'searchProduct',
      component: () => import(/* webpackChunkName: "staff" */ '../views/Product/SearchProduct.vue'),
      meta: {
        layout: 'e-catalogue-layout',
        requiresAuth: true
      }
    },
    {
      path: '/categoryList',
      name: 'categoryList',
      component: () => import(/* webpackChunkName: "staff" */ '../views/Product/CategoryList.vue'),
      meta: {
        layout: 'e-catalogue-layout',
        requiresAuth: true
      }
    },
    {
      path: '/subCategoryList',
      name: 'subCategoryList',
      component: () => import(/* webpackChunkName: "staff" */ '../views/Product/SubCategories.vue'),
      meta: {
        layout: 'e-catalogue-layout',
        requiresAuth: true
      }
    },
    {
      path: '/productBanner',
      name: 'Product-Banner',
      component: () => import(/* webpackChunkName: "Product Banner" */ '../views/Product/Banner.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/productBanner/new',
      name: 'Product-Banner-new',
      component: () => import(/* webpackChunkName: "Product Banner" */ '../views/Product/BannerForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/productBanner/:id',
      name: 'Product-Banner-edit',
      component: () => import(/* webpackChunkName: "Product Banner" */ '../views/Product/BannerForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/contactUs',
      name: 'contact-us',
      component: () => import(/* webpackChunkName: "barcode-scan" */ '../components/contactUs.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/complain',
      name: 'complain',
      component: () => import(/* webpackChunkName: "complain" */ '../views/contactUs/TicketListForTest.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/complain-tickets',
      name: 'complain-tickets',
      component: () => import(/* webpackChunkName: "complain" */ '../views/contactUs/TicketListForAll.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/complain/:id',
      name: 'complain-view',
      component: () => import(/* webpackChunkName: "complain" */ '../views/contactUs/modifyTicket.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/orderHistory',
      name: 'Order-History',
      component: () => import(/* webpackChunkName: "staff" */ '../views/Product/OrderHistory.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: false
      }
    },
    {
      path: '/product-cata',
      name: 'product-cata',
      component: () => import(/* webpackChunkName: "staff" */ '../views/Product/ProductCart.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/eSearchProduct',
      name: 'eSearchProduct',
      component: () => import(/* webpackChunkName: "staff" */ '../views/ECatalogue/ESearchProduct.vue'),
      meta: {
        layout: 'e-catalogue-layout',
        requiresAuth: false
      }
    },
    {
      path: '/eCategoryList',
      name: 'eCategoryList',
      component: () => import(/* webpackChunkName: "staff" */ '../views/ECatalogue/ECategoryList.vue'),
      meta: {
        layout: 'e-catalogue-layout',
        requiresAuth: false
      }
    },
    {
      path: '/eSubCategoryList',
      name: 'eSubCategoryList',
      component: () => import(/* webpackChunkName: "staff" */ '../views/ECatalogue/ESubCategories.vue'),
      meta: {
        layout: 'e-catalogue-layout',
        requiresAuth: false
      }
    },
    {
      path: '/eProduct-cata',
      name: 'eProduct-cata',
      component: () => import(/* webpackChunkName: "staff" */ '../views/ECatalogue/EProductCart.vue'),
      meta: {
        layout: 'e-catalogue-layout',
        requiresAuth: false
      }
    },
    {
      path: '/eOrderHistory',
      name: 'eOrder-History',
      component: () => import(/* webpackChunkName: "staff" */ '../views/ECatalogue/EOrderHistory.vue'),
      meta: {
        layout: 'e-catalogue-layout',
        requiresAuth: false
      }
    },
    {
      path: '/product-catalogue/:id',
      name: 'product-catalogue',
      component: () => import(/* webpackChunkName: "staff" */ '../views/Retail/RetailList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: false
      }
    },
    {
      path: '/product-cart/:id',
      name: 'product-cart',
      component: () => import(/* webpackChunkName: "staff" */ '../views/Retail/RetailCart.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: false
      }
    },
    {
      path: '/role',
      name: 'role',
      component: () => import(/* webpackChunkName: "Role" */ '../views/Role/RoleList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/role/new',
      name: 'role-new',
      component: () => import(/* webpackChunkName: "RoleForm" */ '../views/Role/RoleForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/role/:id',
      name: 'role-edit',
      component: () => import(/* webpackChunkName: "Role" */ '../views/Role/RoleForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/store',
      name: 'store',
      component: () => import(/* webpackChunkName: "staff" */ '../views/Store/StoreList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/store/new',
      name: 'store-new',
      component: () => import(/* webpackChunkName: "staff" */ '../views/Store/StoreForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/store/:id',
      name: 'store-edit',
      component: () => import(/* webpackChunkName: "staff" */ '../views/Store/StoreForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/order-recordings',
      name: 'order-recordings',
      component: () => import(/* webpackChunkName: "staff" */ '../views/OrderRecording/OrderRecordingList'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/order',
      name: 'order',
      component: () => import(/* webpackChunkName: "staff" */ '../views/Order/OrderList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/online-order-list',
      name: 'OnlineOrder',
      component: () => import(/* webpackChunkName: "staff" */ '../views/Order/OnlineOrder.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/b2b-order',
      name: 'b2b-order',
      component: () => import(/* webpackChunkName: "staff" */ '../views/B2Borders/B2BordersList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/b2b-order/:id',
      name: 'b2border-edit',
      component: () => import(/* webpackChunkName: "staff" */ '../views/B2Borders/B2BorderForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/order-mis',
      name: 'order-mis',
      component: () => import(/* webpackChunkName: "staff" */ '../views/Order/POSReport.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/order/report',
      name: 'order-report',
      component: () => import(/* webpackChunkName: "staff" */ '../views/Order/orderReport.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/sales/report',
      name: 'sales-report',
      component: () => import(/* webpackChunkName: "staff" */ '../views/SalesReport/SalesReportList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/b2b/report',
      name: 'b2b-report',
      component: () => import(/* webpackChunkName: "staff" */ '../views/B2BReport/B2BReportList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/sales/category-wise',
      name: 'sales-category-wise',
      component: () => import(/* webpackChunkName: "staff" */ '../views/SalesReport/CategoryWiseReport.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/order/new/:customermobile',
      name: 'order-new',
      component: () => import(/* webpackChunkName: "staff" */ '../views/Order/OrderForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/orderFormBoxV2/new',
      name: 'OrderFormMagento-new',
      component: () => import('../views/Order/OrderFormMagento.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/orderFormBoxV2/:id',
      name: 'OrderFormMagento-edit',
      component: () => import('../views/Order/OrderFormMagento.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/orderErrorPage',
      name: 'OrderErrorPage',
      component: () => import('../components/OrderErrorPage.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/order/:id',
      name: 'order-edit',
      component: () => import(/* webpackChunkName: "staff" */ '../views/Order/OrderForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/setting',
      name: 'setting',
      component: () => import(/* webpackChunkName: "setting" */ '../views/Setting/SettingList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/setting/new',
      name: 'setting-new',
      component: () => import(/* webpackChunkName: "setting" */ '../views/Setting/SettingForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/setting/:id',
      name: 'setting-edit',
      component: () => import(/* webpackChunkName: "setting" */ '../views/Setting/SettingForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/offer/new',
      name: 'offer-new',
      component: () => import(/* webpackChunkName: "Pd Offer Form" */ '../views/PDOffers/pdForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/offer/:id',
      name: 'offer-edit',
      component: () => import(/* webpackChunkName: "Pd Offer Form" */ '../views/PDOffers/pdForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/offers',
      name: 'offers',
      component: () => import(/* webpackChunkName: "PD Offers" */ '../views/PDOffers/pdOffersList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/target-upload',
      name: 'target-upload',
      component: () => import(/* webpackChunkName: "Target Upload" */ '../views/TargetUpload/TargetList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    // {
    //   path: '/product-catalogues',
    //   name: 'product-catalogues',
    //   component: () => import(/* webpackChunkName: "catalogue" */ '../views/ProductCatalogues/ProductCatalogues.vue'),
    //   meta: {
    //     layout: 'backend-layout',
    //     requiresAuth: true
    //   }
    // },
    {
      path: '/product/list-byGroup',
      name: 'products',
      component: () => import(/* webpackChunkName: "Products" */ '../views/ProductCatalogues/Products.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/productsNew',
      name: 'productsNew',
      component: () => import(/* webpackChunkName: "Products" */ '../views/ProductCatalogues/ProductsNew.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/productCalendar',
      name: 'calendar',
      component: () => import(/* webpackChunkName: "Calendar" */ '../views/Calendar/CalendarList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    }
  ]
});

router.beforeEach((to, _from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters['auth/isLoggedIn']()) {
      next();
    } else {
      next('/login');
    }
  } else {
    next();
  }
});

export default router;
