export default class B2BReport {
  constructor({
    _id,
    bdaName,
    storeId,
    totalOrderCreated,
    totalItemCount,
    sumOfQuantity,
    sumOfCartValue,
    totalChallanGenerated,
    totalItemBilled,
    sumOfQuantityBilled,
    sumOfChallanValue,
    index = 0
  }) {
    this.index = index + 1;
    this.bdaName = bdaName || '';
    this.storeId = storeId || '';
    this.totalOrderCreated = totalOrderCreated || 0;
    this.totalItemCount = totalItemCount || 0;
    this._id = _id || ''; // eslint-disable-line
    this.sumOfQuantity = sumOfQuantity || 0;
    this.sumOfCartValue = (sumOfCartValue || 0).toFixed(2);
    this.totalChallanGenerated = totalChallanGenerated || 0;
    this.totalItemBilled = totalItemBilled || 0;
    this.sumOfQuantityBilled = (sumOfQuantityBilled || 0).toFixed(2);
    this.sumOfChallanValue = (sumOfChallanValue || 0).toFixed(2);
  }
}
