// import _ from 'lodash';
import productListService from '@/services/productListService';
import ProductList from '../../model/productList';

const state = {
  baseUrl: '/product-catalogue',
  products: {},
  loading: false,
  storeList: []
};

const actions = {
  listProducts({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setProducts', { products: {} });

    productListService
      .listProducts({ id })
      .then(response => {
        commit('setProducts', { products: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  listStore({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setStoreList', { storeList: [] });

    productListService
      .listStore({ query })
      .then(response => {
        commit('setStoreList', { storeList: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  postOne({ dispatch, commit }, { order, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    productListService
      .postOne({ order })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'bottom-end',
            title: '',
            text: 'New order has been successfully created .'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },

  setProducts(state, { products }) {
    state.products = new ProductList({
      id: products._id, // eslint-disable-line
      active: products.active,
      products: products.products,
      isPublic: products.isPublic,
      catalogueTitle: products.catalogueTitle,
      storeid: products.storeid,
      storeName: products.storeName
    });
    state.loading = false;
  },
  setStoreList(state, { storeList }) {
    state.storeList = storeList;
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
