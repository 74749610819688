import _ from 'lodash';
import orderReportServie from '../../services/orderReportService';
import OrderReport from '../../model/orderReport';


const state = {
  baseUrl: '/order/report2',
  orderReportList: [],
  loading: false,
  downloadOrderReport: []
}

const actions = {

  list({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    orderReportServie
      .list({ query })
      .then(response => {
        Object.keys(response.data).forEach(key => {
          commit('setorderReportList', { orderReportList: response.data[key].ListByStore });
        })
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  download({ dispatch, commit }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setDownloadOrderReport', { downloadOrderReport: [] });
    commit('startRequest');


    orderReportServie
      .list({ query })
      .then(response => {
        Object.keys(response.data).forEach(key => {
          commit('setDownloadOrderReport', { downloadOrderReport: response.data[key].ListByStore });
        })
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  },
  setDownloadListToEmpty({ commit }) {
    commit('setDownloadOrderReport', { downloadOrderReport: [] });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  finishRequest(state) {
    state.loading = false;
  },
  setDownloadOrderReport(state, { downloadOrderReport }) {
    state.downloadOrderReport = _.map(downloadOrderReport, (report, index) => {
      const formattedData = new OrderReport({
        index,
        _id: report._id, // eslint-disable-line
        storeName: report.storeName,
        storeCluster: report.storeCluster,
        storeRegion: report.storeRegion,
        storeFormat: report.storeFormat,
        orderCreated: report.orderCreated,
        orderAnknowleged: report.orderAnknowleged,
        pickingDone: report.pickingDone,
        challanGenerated: report.challanGenerated,
        delivered: report.delivered,
        total: report.total,
        challanValue: report.challanValue
      });
      return formattedData;
    });
    state.loading = false;
  },
  setorderReportList(state, { orderReportList }) {
    state.orderReportList = _.map(orderReportList, (report, index) => {
      const formattedData = new OrderReport({
        index,
        _id: report._id, // eslint-disable-line
        storeName: report.storeName,
        storeCluster: report.storeCluster,
        storeRegion: report.storeRegion,
        storeFormat: report.storeFormat,
        orderCreated: report.orderCreated,
        orderAnknowleged: report.orderAnknowleged,
        pickingDone: report.pickingDone,
        challanGenerated: report.challanGenerated,
        delivered: report.delivered,
        total: report.total,
        challanValue: report.challanValue
      });
      return formattedData;
    });
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
