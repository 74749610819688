export default class Catalogue {
  constructor({ id, productName, price, articleCode, mrp, scale2Price, scale2Qty, scale3Price, scale3Qty }) {
    this.id = id || '1';
    this.productName = productName;
    this.price = price;
    this.articleCode = articleCode || '';
    this.mrp = mrp || 0;
    this.scale2Price = scale2Price || 0;
    this.scale2Qty = scale2Qty || 0;
    this.scale3Price = scale3Price || 0;
    this.scale3Qty = scale3Qty || 0;
  }
}
