import _ from 'lodash';
import contactUsService from '../../services/contactUsService';
import Ticket from '../../model/contactUs';

const state = {
  baseUrl: '/complain',
  pagination: {},
  tickets: [],
  loading: false,
  ticket: null,
  videoUrl: null
};

const actions = {
  uploadImage({ dispatch, commit }, query = {}, router) {
    dispatch('alert/clear', {}, { root: true });
    const { file } = query;
    contactUsService
      .getSignedUrl({ filename: file.name, filetype: file.type, file })
      .then(response => {
        contactUsService
          .uploadImage({ url: response.data, file })
          .then(res => {
            commit('Nothing', res);
          })
          .catch(e => {
            commit('requestFailed');
            dispatch('common/handleServiceException', { e, router }, { root: true });
          });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  listTickets({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setTickets', { tickets: [], pagination: {} });

    commit('startRequest');

    contactUsService
      .listTickets({ query })
      .then(response => {
        commit('setTickets', { tickets: response.data.rows, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  postOne({ dispatch, commit }, { ticket, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    contactUsService
      .postOne({ ticket })
      .then(_response => {

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  getOne({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setTicket', { ticket: {} });

    contactUsService
      .getOne({ id })
      .then(response => {
        commit('setTicket', { ticket: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  patchOne({ dispatch, commit }, { id, ticket, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    contactUsService
      .patchOne({ id, newTicket: ticket })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'Ticket has been updated.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  deleteOne({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    contactUsService
      .deleteOne({ id })
      .then(_response => {
        dispatch('listTickets', { id });
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: ' Ticket has been deleted.'
          },
          { root: true }
        );
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  setTickets(state, { tickets, pagination }) {
    let row = 1;
    state.tickets = _.map(tickets, ticket => {
      const newTickets = new Ticket({
        id: ticket._id, // eslint-disable-line
        rowNum: row,
        name: ticket.name,
        mobile: ticket.mobile,
        email: ticket.email,
        issue: ticket.issue,
        details: ticket.details,
        priority: ticket.priority,
        storeid: ticket.storeid,
        complainNumber: ticket.complainNumber,
        imageUrl: ticket.imageUrl ? ticket.imageUrl : '-',
        resolvedAt: ticket.resolvedAt,
        escalationTime: ticket.escalationTime ? ticket.escalationTime : '-',
        complainSource: ticket.complainSource,
        currentStatus: ticket.currentStatus,
        isResolved: ticket.isResolved === true ? 'True' : 'False',
        issuePriority: ticket.issuePriority,
        submittedByRole: ticket.submittedByRole ? ticket.submittedByRole.join(',') : '',
        submittedByName: ticket.submittedByName,
        createdAt: ticket.createdAt

      });
      row += 1;
      return newTickets;
    });
    state.pagination = pagination;
    state.loading = false;
  },
  setTicket(state, { ticket }) {
    state.ticket = new Ticket({
      id: ticket._id, // eslint-disable-line
      name: ticket.name,
      mobile: ticket.mobile,
      email: ticket.email,
      issue: ticket.issue,
      details: ticket.details,
      priority: ticket.priority,
      storeid: ticket.storeid,
      complainNumber: ticket.complainNumber,
      imageUrl: ticket.imageUrl,
      resolvedAt: ticket.resolvedAt,
      escalationTime: ticket.escalationTime,
      complainSource: ticket.complainSource,
      currentStatus: ticket.currentStatus,
      isResolved: ticket.isResolved,
      issuePriority: ticket.issuePriority,
      submittedByRole: ticket.submittedByRole ? ticket.submittedByRole.join(',') : '',
      submittedByName: ticket.submittedByName,
      createdAt: ticket.createdAt
    });
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
