import _ from 'lodash';
import outboundService from '../../services/outboundService';
import OutboundLog from '../../model/outbound';

const state = {
  baseUrl: '/outbound-log',
  outboundLogList: [],
  downloadOutboundLogList: [],
  pagination: {},
  loading: false
};

const actions = {
  list({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    outboundService
      .list({ query })
      .then(response => {
        commit('setOutboundLogList', { outboundLogList: response.data.rows, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  download({ dispatch, commit }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setDownloadOutboundLogList', { downloadOutboundLogList: [] });

    outboundService
      .list({ query })
      .then(response => {
        commit('setDownloadOutboundLogList', { downloadOutboundLogList: response.data.rows });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  },
  setDownloadListToEmpty({ commit }) {
    commit('setDownloadOutboundLogList', { downloadOutboundLogList: [] });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  finishRequest(state) {
    state.loading = false;
  },
  setOutboundLogList(state, { outboundLogList, pagination }) {
    let row = pagination ? pagination.first_row_no : 1;
    state.outboundLogList = _.map(outboundLogList, outbound => {
      const formatted = new OutboundLog({
        id: row,
        customerMobile: outbound.customerMobile,
        customerName: outbound.customerName,
        callDuration: outbound.callDuration,
        status: outbound.status,
        callStatus: outbound.callStatus,
        feedback: outbound.feedback,
        storeid: outbound.storeid,
        createdAt: outbound.createdAt,
        updatedBy: outbound.updatedBy ? `${outbound.updatedBy.mobile}-${outbound.updatedBy.name}` : null
      });
      row -= 1;
      return formatted;
    });
    state.pagination = pagination;
    state.loading = false;
  },
  setDownloadOutboundLogList(state, { downloadOutboundLogList }) {
    let row = 1;
    state.downloadOutboundLogList = _.map(downloadOutboundLogList, outbound => {
      const formattedOrders = new OutboundLog({
        id: row,
        customerMobile: outbound.customerMobile,
        customerName: outbound.customerName,
        callDuration: outbound.callDuration,
        status: outbound.status,
        callStatus: outbound.callStatus,
        feedback: outbound.feedback,
        storeid: outbound.storeid,
        createdAt: outbound.createdAt,
        updatedBy: outbound.updatedBy ? `${outbound.updatedBy.mobile}-${outbound.updatedBy.name}` : null
      });
      row += 1;
      return formattedOrders;
    });
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
