import axios from 'axios';
import configService from '@/services/configService';

export default {
  async listCatalogues() {
    const url = `${configService.get('apiUrl')}/product-catalogue`;

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async listProducts({ catalogue }) {
    // TODO: make products api call
    return {
      data: {
        rows: catalogue.products
      }
    };
  },
  async addProduct({ product }) {
    const url = `${configService.get('apiUrl')}/product-catalogue/add-product`;

    return axios
      .post(url, product)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async deleteProduct({ catalogueId, productId }) {
    return axios
      .delete(`${configService.get('apiUrl')}/product-catalogue/remove-product/${catalogueId}/${productId}`)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async editProduct({ product, productId, catalogueId }) {
    const url = `${configService.get('apiUrl')}/product-catalogue/edit-product/${catalogueId}/${productId}`;

    return axios
      .patch(url, product)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async addCatalogue({ params }) {
    const url = `${configService.get('apiUrl')}/product-catalogue`;

    return axios
      .post(url, params)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async editCatalogue({ params, catalogueId }) {
    const url = `${configService.get('apiUrl')}/product-catalogue/${catalogueId}`;

    return axios
      .patch(url, params)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async deleteCatalogue({ catalogueId }) {
    return axios
      .delete(`${configService.get('apiUrl')}/product-catalogue/${catalogueId}`)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  }
};
