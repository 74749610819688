import analyticsService from '@/services/analyticsService';
import _ from 'lodash';
import Analytics from '../../model/analytics';

const state = {
  baseUrl: '/',
  analyticsList: [],
  downloadAnalyticsList: [],
  downloadReport: [],
  pagination: null,
  loading: false
};

const actions = {
  list({ dispatch, commit }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setAnalyticsList', { analyticsList: [], pagination: null });
    commit('startRequest');

    analyticsService
      .list({ query })
      .then(response => {
        commit('setAnalyticsList', { analyticsList: response.data.rows, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  },
  download({ dispatch, commit }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setAnalyticsDownloadList', { downloadAnalyticsList: [] });

    analyticsService
      .list({ query })
      .then(response => {
        commit('setAnalyticsDownloadList', { downloadAnalyticsList: response.data.rows });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  },
  setDownloadListToEmpty({ commit }) {
    commit('setAnalyticsDownloadList', { downloadAnalyticsList: [] });
  },
  report({ dispatch, commit }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setDownloadReport', { downloadReport: [] });

    analyticsService
      .report({ query })
      .then(response => {
        commit('setDownloadReport', { downloadReport: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  },
  setDownloadReportToEmpty({ commit }) {
    commit('setDownloadReport', { downloadReport: [] });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  setAnalyticsList(state, { analyticsList, pagination }) {
    state.analyticsList = _.map(analyticsList, analytics => {
      const formatAnalytics = new Analytics({
        id: analytics.id,
        callerId: analytics.callerid,
        step: analytics.step,
        pincode: analytics.pincode,
        time: analytics.calltime,
        storeid: analytics.store_id,
        duration: analytics.duration,
        managerNumber: analytics.manager_number
      });
      return formatAnalytics;
    });
    state.pagination = pagination;
    state.loading = false;
  },
  setAnalyticsDownloadList(state, { downloadAnalyticsList }) {
    state.downloadAnalyticsList = _.map(downloadAnalyticsList, analytics => {
      const formatAnalytics = new Analytics({
        id: analytics.id,
        callerId: analytics.callerid,
        step: analytics.step,
        pincode: analytics.pincode,
        time: analytics.calltime,
        storeid: analytics.store_id,
        duration: analytics.duration,
        managerNumber: analytics.manager_number
      });
      return formatAnalytics;
    });
    state.loading = false;
  },
  setDownloadReport(state, { downloadReport }) {
    state.downloadReport = downloadReport;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
