import _ from 'lodash';
import permissionsService from '@/services/permissionsService';
import Permissions from '../../model/permissions';

const state = {
  baseUrl: '/permissions',
  permissions: [],
  permissionsList: [],
  pagination: {},
  permission: null,
  loading: false
};

const actions = {
  listPermission({ dispatch, commit }, { router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    permissionsService
      .listPermission({ router })
      .then(response => {
        commit('setPermissions', { permissions: response.data });
        commit('setPermissionsList', { permissionsList: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  getOne({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setPermission', { permission: {} });

    permissionsService
      .getOne({ id })
      .then(response => {
        commit('setPermission', { permission: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  postOne({ dispatch, commit }, { permission, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    permissionsService
      .postOne({ permission })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'New Permission has been added.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  patchOne({ dispatch, commit }, { id, permission, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    permissionsService
      .patchOne({ id, permission })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'Permission has been updated.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  setPermissionsList(state, { permissionsList }) {
    state.permissionsList = _.map(permissionsList, (permissions, index) => {
      const newPermissions = new Permissions({
        index,
        id: permissions._id, // eslint-disable-line
        name: permissions.name,
        description: permissions.description
      });

      return newPermissions;
    });
    state.loading = false;
  },
  setPermission(state, { permission }) {
    // const { _id } = permission;
    state.permission = new Permissions({
      id: permission._id, // eslint-disable-line
      name: permission.name,
      description: permission.description
    });
    state.loading = false;
  },
  setPermissions(state, { permissions }) {
    if (permissions) {
      const permission = permissions.map(({ name, _id }) => ({ text: name, value: _id }));
      state.permissions = permission;
    }
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
