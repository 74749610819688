import _ from 'lodash';
import catalogueBannerService from '../../services/catalogueBannerService';
import Banner from '../../model/catalogueBanner';

const state = {
  baseUrl: '/productBanner',
  banners: [],
  customerBanners: [],
  pagination: {},
  banner: null,
  loading: false
};

const actions = {
  listBanner({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    catalogueBannerService
      .listBanner({ query })
      .then(response => {
        commit('setBanners', { banners: response.data.rows, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  listCatalogueBanner({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    catalogueBannerService
      .listCatalogueBanner({ query, router })
      .then(response => {
        commit('setCustomerBanners', { customerBanners: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  getOne({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    catalogueBannerService
      .getOne({ id })
      .then(response => {
        commit('setBanner', { banner: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  postOne({ dispatch, commit }, { productBanner, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    catalogueBannerService
      .postOne({ productBanner })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'New Banner has been added.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  patchOne({ dispatch, commit }, { id, productBanner, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    catalogueBannerService
      .patchOne({ id, productBanner })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'Banner has been updated.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  deleteOne({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    catalogueBannerService
      .deleteOne({ id })
      .then(_response => {
        dispatch('listBanner', { router });
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'Banner has been deleted.'
          },
          { root: true }
        );
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  finishRequest(state) {
    state.loading = false;
  },
  setBanners(state, { banners, pagination }) {
    let row = 1;
    state.banners = _.map(banners, banner => {
      const { _id } = banner;
      const formattedBanners = new Banner({
        id: _id,
        rowNum: row,
        title: banner.title,
        active: banner.active === true ? 'True' : 'False',
        startDate: banner.startDate,
        endDate: banner.endDate,
        storeids: banner.storeids ? banner.storeids.join(',') : '',
        regions: banner.regions ? banner.regions.join(',') : '',
        clusters: banner.clusters ? banner.clusters.join(',') : '',
        formats: banner.formats ? banner.formats.join(',') : '',
        createdAt: banner.createdAt
      });
      row += 1;
      return formattedBanners;
    });
    state.pagination = pagination;
    state.loading = false;
  },
  setCustomerBanners(state, { customerBanners }) {
    state.customerBanners = customerBanners;
    state.loading = false;
  },
  setBanner(state, { banner }) {
    const { _id } = banner;
    state.banner = new Banner({
      id: _id,
      title: banner.title,
      active: banner.active,
      startDate: banner.startDate,
      endDate: banner.endDate,
      storeids: banner.storeids,
      regions: banner.regions,
      clusters: banner.clusters,
      formats: banner.formats,
      createdAt: banner.createdAt
    });
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
