import productService from '../../services/productService';

const state = {
  baseUrl: '/product/list-byGroup',
  products: [],
  productsWithCategory: [],
  categoryList: [],
  customerList: [],
  storeList: [],
  orders: [],
  isProductFormOpen: false,
  selectedProduct: null,
  loadingCatalogues: false,
  loading: false,
  previewProducts: [],
  pagination: {},
  cartItems: {},
  selectedSubCategoryStore: {},
  offer: []
};

const actions = {
  listProducts({ dispatch, commit }, { router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setProducts', { products: [] });

    productService
      .listProducts({ router })
      .then(response => {
        commit('setProducts', { products: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  listProductsCategory({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    productService
      .listProductsByCategory({ query, router })
      .then(response => {
        commit('setProductsWithCategory', { productsWithCategory: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  listproductPreview({ dispatch, commit }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setPreviewProducts', { previewProducts: [] });

    productService
      .listproductPreview()
      .then(response => {
        commit('setPreviewProducts', { previewProducts: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  },
  listCategory({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    productService
      .listCategory({ query, router })
      .then(response => {
        commit('setCategoryList', { categoryList: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  addCategory({ dispatch }, { router, params }) {
    productService
      .addCategory({ category: params })
      .then(response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: 'Success',
            message: 'Category added successfully'
          },
          { root: true }
        );
        dispatch('listCategory', { router });
        return response.data;
      })
      .catch(e => {
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  editCategory({ dispatch }, { router, params, categoryId }) {
    dispatch('alert/clear', {}, { root: true });
    productService
      .editCategory({ category: params, categoryId })
      .then(response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: 'Success',
            message: 'Category updated successfully'
          },
          { root: true }
        );
        return response.data;
      })
      .catch(e => {
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  deleteCategory({ dispatch }, { router, categoryId }) {
    dispatch('alert/clear', {}, { root: true });
    productService
      .deleteCategory({ categoryId })
      .then(response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: 'Success',
            message: 'Category deleted successfully'
          },
          { root: true }
        );
        dispatch('listCategory', { router });
        return response.data;
      })
      .catch(e => {
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  listCustomer({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    productService
      .listByCustomer({ query, router })
      .then(response => {
        commit('setListCustomer', { customerList: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  listStore({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setStoreList', { storeList: [] });

    productService
      .listStore({ query })
      .then(response => {
        // Delete it after the pilot is over
        // const storePilot = ['H030', 'S053', 'H029', 'H051', 'H049', 'M051'];
        // const filteredStore = response.data.filter(store => storePilot.includes(store.storeid));
        // commit('setStoreList', { storeList: filteredStore });
        commit('setStoreList', { storeList: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  listOrders({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    productService
      .listOrders({ query })
      .then(response => {
        commit('setListOrders', { orders: response.data.rows, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  openAddProduct({ commit }) {
    commit('setProductFormOpen', true);
  },
  closeAddProduct({ commit }) {
    commit('setProductFormOpen', false);
  },
  selectProduct({ commit }, { product }) {
    commit('setProductFormOpen', true);
    commit('setSelectedProduct', product);
  },
  setCartItem({ commit }, { cartItems }) {
    commit('setCartItem', { cartItems });
  },
  addProduct({ dispatch }, { router, params }) {
    productService
      .addProduct({ product: params })
      .then(response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'Product added successfully.'
          },
          { root: true }
        );
        this.dispatch('product/listProductsCategory', {
          query: { category: params.category, subCategory: params.subCategory },
          router
        });
        return response.data;
      })
      .catch(e => {
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },

  editProduct({ dispatch }, { router, params, productId }) {
    dispatch('alert/clear', {}, { root: true });
    productService
      .editProduct({ product: params, productId })
      .then(response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'Product updated successfully.'
          },
          { root: true }
        );
        this.dispatch('product/listProductsCategory', {
          query: { category: params.category, subCategory: params.subCategory },
          router
        });
        return response.data;
      })
      .catch(e => {
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },

  approveProduct({ dispatch }, { router, productIds, active, params }) {
    dispatch('alert/clear', {}, { root: true });
    productService
      .approveProduct({ productIds, active })
      .then(response => {
        this.dispatch('product/listProductsCategory', {
          query: { category: params.category, subCategory: params.subCategory },
          router
        });
        return response.data;
      })
      .catch(e => {
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  deleteProduct({ dispatch }, { router, productId, params }) {
    dispatch('alert/clear', {}, { root: true });
    productService
      .deleteProduct({ productId })
      .then(response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'Product deleted successfully.'
          },
          { root: true }
        );
        this.dispatch('product/listProductsCategory', {
          query: { category: params.category, subCategory: params.subCategory },
          router
        });
        return response.data;
      })
      .catch(e => {
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  uploadProduct({ dispatch, commit }, { router, data }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    productService
      .uploadProduct({ data })
      .then(response => {
        commit('finishRequest');
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'Products uploaded successfully.'
          },
          { root: true }
        );
        this.dispatch('product/listProductsCategory', {
          query: { category: data[0].category, subCategory: data[0].subCategory },
          router
        });
        return response.data;
      })
      .catch(e => {
        let errorMessage = '';
        if (e.response.data.data) {
          errorMessage = e.response.data.data[0].message;
        }
        commit('requestFailed');
        dispatch(
          'alert/error',
          {
            position: 'center',
            title: errorMessage,
            type: 'error',
            showType: 'dialog',
            text: 'Upload failed, remember to use only sample file provided and check the line number !!'
          },
          { root: true }
        );
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  postOne({ dispatch, commit }, { order, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    productService
      .postOne({ order })
      .then(_response => {
        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  postOneECatalogue({ dispatch, commit }, { order, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    productService
      .postOneECatalogue({ order })
      .then(_response => {
        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  getOffer({ dispatch, commit }, { articleId, storeId, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setOffer', { offer: [] });

    commit('startRequest');

    productService
      .getOffer({ articleId, storeId })
      .then(response => {
        commit('setOffer', { offer: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  deleteCatalogue({ dispatch }, { router, catalogueName }) {
    dispatch('alert/clear', {}, { root: true });
    productService
      .deleteCatalogue({ catalogueName })
      .then(response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'Catalogue deleted successfully.'
          },
          { root: true }
        );
        dispatch('listCatalogue', { router });
        return response.data;
      })
      .catch(e => {
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  setSelectedSubCategory({ commit }, { subCategory }) {
    commit('setSelectedSubCategory', subCategory);
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  setCartItem(state, { cartItems }) {
    state.cartItems = cartItems;
  },
  setStoreList(state, { storeList }) {
    state.storeList = storeList;
    state.loading = false;
  },
  setProducts(state, { products }) {
    state.loadingCatalogues = false;
    state.products = products;
    state.loading = false;
  },
  setOffer(state, { offer }) {
    state.offer = offer;
    state.loading = false;
  },
  setProductsWithCategory(state, { productsWithCategory }) {
    state.productsWithCategory = productsWithCategory;
    state.loading = false;
  },
  setPreviewProducts(state, { previewProducts }) {
    state.previewProducts = previewProducts;
    state.loading = false;
  },
  setListCustomer(state, { customerList }) {
    state.customerList = customerList.map(item => ({
      ...item
    }));
    state.loading = false;
  },
  setCategoryList(state, { categoryList }) {
    state.categoryList = categoryList;
    // state.loading = false;
  },
  setProductFormOpen(state, payload) {
    state.isProductFormOpen = payload;
  },
  setSelectedProduct(state, selectedProduct) {
    state.selectedProduct = selectedProduct;
  },
  startCataloguesRequest(state) {
    state.loadingCatalogues = true;
  },
  cataloguesRequestFailed(state) {
    state.loadingCatalogues = false;
  },
  setListOrders(state, { orders }) {
    state.orders = orders;
    state.loading = false;
  },
  setSelectedSubCategory(state, subCategory) {
    state.selectedSubCategoryStore = subCategory;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
