

export default class OrderReport {
  constructor({
    _id,
    storeName,
    storeCluster,
    storeRegion,
    storeFormat,
    orderCreated,
    orderAnknowleged,
    pickingDone,
    challanGenerated,
    delivered,
    total,
    challanValue,
    index = 0

  }) {
    this.index = index + 1;
    this.storeRegion = storeRegion || "";
    this.storeCluster = storeCluster || "";
    this.storeName = storeName || "";
    this._id = _id || ""; // eslint-disable-line
    this.storeFormat = storeFormat || "";
    this.orderCreated = orderCreated || 0;
    this.orderAnknowleged = orderAnknowleged || 0;
    this.pickingDone = pickingDone || 0;
    this.challanGenerated = challanGenerated || 0;
    this.challanValue = challanValue || 0;
    this.delivered = delivered || 0;
    this.total = total || 0;
  }
}
