import Vue from 'vue';
import Vuex from 'vuex';

import common from './modules/common';
import auth from './modules/auth';
import alert from './modules/alert';
import analytics from './modules/analytics';
import calls from './modules/calls';
import user from './modules/user';
import order from './modules/order';
import todo from './modules/todo';
import mis from './modules/mis';
import permission from './modules/permission';
import outbound from './modules/outbound';
import setting from './modules/setting';
import recording from './modules/recording';
import role from './modules/role';
import catalogue from './modules/catalogue';
import store from './modules/store';
import productCatalogues from './modules/productCatalogues';
import productList from './modules/productList';
import customerMetaData from './modules/customerMetaData';
import permissions from './modules/permissions';
import orderPosMis from './modules/orderPosMis';
import salesReport from './modules/salesReport';
import B2BReport from './modules/B2BReport';
import orderReport from './modules/orderReport';
import pdOffer from './modules/pdOffer';
import target from './modules/target';
import outboundCaller from './modules/outboundCaller';
import product from './modules/product';
import calendar from './modules/calendar';
import categoryReport from './modules/categoryReport';
import catalogueBanner from './modules/catalogueBanner';
import contactUs from './modules/contactUs';
import b2BOrder from './modules/b2BOrder';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    common,
    auth,
    alert,
    analytics,
    calls,
    user,
    order,
    mis,
    todo,
    role,
    outbound,
    permission,
    setting,
    recording,
    catalogue,
    store,
    productCatalogues,
    productList,
    customerMetaData,
    permissions,
    orderPosMis,
    salesReport,
    B2BReport,
    orderReport,
    pdOffer,
    target,
    outboundCaller,
    product,
    calendar,
    categoryReport,
    catalogueBanner,
    contactUs,
    b2BOrder
  },
  strict: true
});
