import moment from 'moment';

export default class Recordings {
  constructor({ id, callerId, companyId, managerNumber, regTime, storeid, filename, feedback }) {
    this.id = id || '1';
    this.callerId = callerId;
    this.manager_number = managerNumber;
    this.storeid = storeid;
    this.filename =
      companyId === 0
        ? `https://spencer-rec.s3.amazonaws.com/${filename}.wav`
        : `https://nature-rec.s3.amazonaws.com/${filename}.wav`;
    this.time = moment(regTime).format('DD-MM-YYYY hh:mm  A');
    this.feedback = feedback || 0;
  }
}
