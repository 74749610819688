import _ from 'lodash';
import b2bOrderService from '@/services/b2bOrderService';
import B2BOrder from '@/model/b2BOrder';

const state = {
  baseUrl: '/b2b-order',
  b2borders: [],
  pagination: {},
  b2BOrder: [],
  downloadOrdersList: [],
  loading: false,
  downloading: false
};

const actions = {
  list({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setOrders', { b2borders: [] });
    commit('startRequest');

    b2bOrderService
      .list({ query })
      .then(response => {
        commit('setOrders', { b2borders: response.data.rows, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  markOrderPrinted({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    const body = { isPrinted: true };
    b2bOrderService
      .markOrderPrinted({ id, body })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'bottom-end',
            title: '',
            text: 'Order has been updated.'
          },
          { root: true }
        );
        dispatch('list', { query: {} });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  getOne({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    commit('setOrder', { b2BOrder: {} });

    b2bOrderService
      .getOne({ id })
      .then(response => {
        commit('setOrder', { b2BOrder: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  async download({ dispatch, commit }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setDownloadOrdersList', { downloadOrdersList: [], pagination: null });
    commit('startDownloading');

    let stopDownload = false;
    const pageSize = 5000;
    const accData = [];

    for (let i = 0; !stopDownload; ) {
      try {
        // eslint-disable-next-line no-await-in-loop
        const response = await b2bOrderService.list({
          query: { ...query, page: i + 1, page_size: pageSize }
        });
        if (response.data.pagination.total_rows > 5000) {
          dispatch(
            'alert/success',
            {
              showType: 'toast',
              position: 'center',
              title: 'Preparing your download, please wait...'
            },
            { root: true }
          );
        }
        if (response.data.rows.length === 0) {
          stopDownload = true;
        }
        accData.push(...response.data.rows);
        i += 1;
      } catch (e) {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
        return;
      }
    }
    commit('setDownloadOrdersList', {
      downloadOrdersList: accData,
      pagination: null
    });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  startDownloading(state) {
    state.downloading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  finishRequest(state) {
    state.loading = false;
  },
  finishDownloading(state) {
    state.downloading = false;
  },
  setOrders(state, { b2borders, pagination }) {
    let row = pagination ? pagination.first_row_no : 1;
    state.b2borders = _.map(b2borders, (order, index) => {
      const { _id } = order;
      const posItems = order?.orderId?.posItems;
      const products = [];
      const quantity = [];
      const articleCode = [];
      const mrp = [];
      const sellingPrice = [];
      order.orderId?.products.forEach(product => {
        const name = product.split(':::').length > 1 ? product.split(':::')[0] : product;
        const tempQuantity = product.split(':::').length > 1 ? product.split(':::')[1] : 1;
        const tempArticleCode = product.split(':::').length > 1 ? product.split(':::')[2] : 1;
        const tempMrp = product.split(':::').length > 1 ? product.split(':::')[3] : 1;
        const tempSellingPrice = product.split(':::').length > 1 ? product.split(':::')[4] : 1;

        products.push(name);
        quantity.push(tempQuantity);
        articleCode.push(tempArticleCode);
        mrp.push(tempMrp);
        sellingPrice.push(tempSellingPrice);
      });
      // const products = order.orderId?.products.map(product => {
      //   if (product) {
      //     const name = product.split(':::').length > 1 ? product.split(':::')[0] : product;
      //     return `${name}`;
      //   }
      //   return null;
      // });
      // const quantity = order.orderId?.products.map(product => {
      //   if (product) {
      //     const quantity = product.split(':::').length > 1 ? product.split(':::')[1] : 1;
      //     return `${quantity}`;
      //   }
      //   return null;
      // });
      //  products, createdBy, storeId, createdAt, gstNumber, employeeId, address;
      const formattedOrders = new B2BOrder({
        id: row,
        rowNum: index,
        orderId: _id,
        salesAssociateName: order.salesAssociateName,
        customerName: order?.orderId?.name,
        address: order?.orderId?.address,
        source: order?.orderId?.source,
        products,
        posItems,
        quantity,
        articleCode,
        isPrinted: order?.isPrinted,
        mrp,
        sellingPrice,
        description: order?.orderId?.description,
        storeId: order.storeId,
        gstNumber: order.gstNumber,
        employeeId: order.employeeId,
        createdAt: order.createdAt ? order.createdAt : '',
        customerMobile: order?.orderId?.customerMobile,
        dateCreated: order?.orderId?.createdAt,
        isAcknowledged: order?.orderId?.isAcknowledged,
        isDelivered: order?.orderId?.isDelivered,
        acknowledgedTime: order?.orderId?.acknowledgedTime,
        packagedTime: order?.orderId?.packagedTime,
        posPingTime: order?.orderId?.posPingTime,
        pinCode: order?.orderId?.pinCode,
        actualDeliveredTime: order?.orderId?.actualDeliveredTime,
        imageURL: order?.orderId?.imageURL ? order?.orderId?.imageURL : '',
        modeOfPayment: order?.orderId?.modeOfPayment,
        deliveryCode: order?.orderId?.deliveryCode
      });
      row -= 1;
      return formattedOrders;
    });
    state.pagination = pagination;
    state.loading = false;
  },
  setOrder(state, { b2BOrder }) {
    const { _id } = b2BOrder;
    const orderIdFor = b2BOrder?.orderId?._id;
    state.b2BOrder = new B2BOrder({
      id: _id,
      orderId: orderIdFor,
      rowNum: 0,
      salesAssociateName: b2BOrder.salesAssociateName,
      address: b2BOrder?.orderId?.address,
      source: b2BOrder?.orderId?.source,
      products: b2BOrder?.orderId?.products,
      description: b2BOrder?.orderId?.description,
      customerName: b2BOrder?.orderId?.name,
      storeId: b2BOrder.storeId,
      gstNumber: b2BOrder.gstNumber,
      employeeId: b2BOrder.employeeId,
      createdAt: b2BOrder.createdAt ? b2BOrder.createdAt : '',
      customerMobile: b2BOrder?.orderId?.customerMobile,
      dateCreated: b2BOrder?.orderId?.createdAt,
      isAcknowledged: b2BOrder?.orderId?.isAcknowledged,
      isDelivered: b2BOrder?.orderId?.isDelivered,
      acknowledgedTime: b2BOrder?.orderId?.acknowledgedTime,
      packagedTime: b2BOrder?.orderId?.packagedTime,
      posPingTime: b2BOrder?.orderId?.posPingTime,
      actualDeliveredTime: b2BOrder?.orderId?.actualDeliveredTime,
      imageURL: b2BOrder?.orderId?.imageURL ? b2BOrder?.orderId?.imageURL : '',
      modeOfPayment: b2BOrder?.orderId?.modeOfPayment,
      deliveryCode: b2BOrder?.orderId?.deliveryCode,
      pinCode: b2BOrder?.orderId?.pinCode,
      posItems: b2BOrder?.orderId?.posItems
    });
    state.loading = false;
  },
  setDownloadOrdersList(state, { downloadOrdersList }) {
    let row = 1;
    state.downloadOrdersList = _.map(downloadOrdersList, order => {
      const { _id } = order;

      const formattedOrders = new B2BOrder({
        id: row,
        orderId: _id,
        customerMobile: order?.orderId?.customerMobile,
        salesAssociateName: order.salesAssociateName,
        customerName: order?.orderId?.name,
        gstNumber: order.gstNumber,
        address: order?.orderId?.address,
        source: order?.orderId?.source,
        description: order?.orderId?.description,
        products: order?.orderId?.products,
        storeId: order.storeId,
        dateCreated: order.createdAt,
        dateUpdated: order?.orderId?.updatedAt,
        isAcknowledged: order?.orderId?.isAcknowledged,
        isDelivered: order?.orderId?.isDelivered,
        createdAt: order?.orderId?.createdAt ? order?.orderId?.createdAt : '',
        acknowledgedTime: order?.orderId?.acknowledgedTime,
        packagedTime: order?.orderId?.packagedTime,
        posPingTime: order?.orderId?.posPingTime,
        actualDeliveredTime: order?.orderId?.actualDeliveredTime,
        orderMode: order?.orderId?.orderMode,
        pinCode: order?.orderId?.pinCode,
        billNumber: order?.orderId?.billNumber ? order?.orderId?.billNumber : '',
        posItems: order?.orderId?.posItems?.map(item => item.amount)?.reduce((a, b) => a + b, 0),
        sellQuantity:
          order?.orderId?.posItems?.map(item => ({
            itemName: item.itemName,
            quantity: item.quantity || 0,
            amount: item.amount || 0,
            id: _id,
            mrp: item.mrp ? item.mrp : '',
            articleCode: item.itemCode.slice(-7)
          })) || []
      });
      row += 1;
      return formattedOrders;
    });
    state.downloading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
