import _ from 'lodash';
import outboundCallerService from '@/services/outboundCallerService';
import OutboundCaller from '../../model/outboundCaller';
import BlackListNumber from '../../model/blackListNumber';

const state = {
  baseUrl: '/outboundCallerList',
  outboundCallerList: [],

  downloadOutboundCallerList: [],
  dialedCallList: [],
  downloadDialedCallList: [],
  pagination: {},
  loading: false,
  uploadLoading: false,
  outboundReport: [],
  tellCallerReport: [],
  dayWiseReport: [],
  teleCallerDayWiseReport: [],
  callerReportLive: [],
  lengthOfUpload: 0,
  sizeOfUpload: 0,
  blackListNumber: [],
  downloadBlackListNumbers: [],
  totalDownloadSize: 0,
  downloadProgress: 0,
  startDownloading: false,
  numberList: 0,
  deleteNumberCount: 0
};

const actions = {
  list({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setOutboundCallers', { outboundCallers: [], pagination: {} });

    commit('startRequest');

    outboundCallerService
      .listOutboundCaller({ query })
      .then(response => {
        commit('setOutboundCallers', { outboundCallers: response.data.rows, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  getNumberToDelete({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setNumberOfCaller', { numberList: 0 });

    commit('startRequest');

    outboundCallerService
      .getNumberToDelete({ query })
      .then(response => {
        commit('setNumberOfCaller', { numberList: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  deleteNumbers({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setDeleteNumberCount', { deleteNumberCount: 0 });

    commit('startRequest');

    outboundCallerService
      .deleteNumbers({ query })
      .then(response => {
        commit('setDeleteNumberCount', { deleteNumberCount: response.data });
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: `${response?.data?.deletedCount} Numbers delete successfully`
          },
          { root: true }
        );
        window.location.reload();
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },

  listDialed({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setDialedCalls', { dialedCalls: [], pagination: {} });

    commit('startRequest');

    outboundCallerService
      .dialedList({ query })
      .then(response => {
        commit('setDialedCalls', { dialedCalls: response.data.rows, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  async downloadDialed({ dispatch, commit }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    commit('setDownloadDialed', { downloadDialedCallList: [] });

    commit('startDownload', { startDownloading: true });
    let stopDownload = false;
    const pageSize = 5000;
    const accData = [];
    for (let i = 0; !stopDownload; ) {
      try {
        // eslint-disable-next-line no-await-in-loop
        const response = await outboundCallerService.dialedList({
          query: { ...query, page: i + 1, page_size: pageSize }
        });

        commit('downloadBar', { totalDownloadSize: Math.round(response.data.pagination.total_rows / 5000) });
        if (response.data.pagination.total_rows > 5000) {
          commit('downloadProcess', { downloadProgress: i + 1 });
          dispatch(
            'alert/success',
            {
              showType: 'toast',
              position: 'center',
              title: 'Preparing your download, please wait...'
            },
            { root: true }
          );
        }
        if (response.data.rows.length === 0) {
          stopDownload = true;
        }
        accData.push(...response.data.rows);
        i += 1;
      } catch (e) {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
        commit('startDownload', { startDownloading: false });
        return;
      }
    }
    commit('setDownloadDialed', { downloadDialedCallList: accData });
    commit('startDownload', { startDownloading: false });
  },
  async download({ dispatch, commit }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setDownloadOutboundCallers', { downloadOutboundCallerList: [] });

    let stopDownload = false;
    const pageSize = 5000;
    const accData = [];

    for (let i = 0; !stopDownload; ) {
      try {
        // eslint-disable-next-line no-await-in-loop
        const response = await outboundCallerService.listOutboundCaller({
          query: { ...query, page: i + 1, page_size: pageSize }
        });
        if (response.data.pagination.total_rows > 5000) {
          dispatch(
            'alert/success',
            {
              showType: 'toast',
              position: 'center',
              title: 'Preparing your download, please wait...'
            },
            { root: true }
          );
        }
        if (response.data.rows.length === 0) {
          stopDownload = true;
        }
        accData.push(...response.data.rows);
        i += 1;
      } catch (e) {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
        return;
      }
    }
    commit('setDownloadOutboundCallers', { downloadOutboundCallerList: accData });
  },
  async downloadReport({ dispatch, commit }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setOutboundReport', { outboundReport: [] });
    outboundCallerService
      .callingReport({ query })
      .then(response => {
        commit('setOutboundReport', { outboundReport: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  },
  async downloadTelleReport({ dispatch, commit }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setTelleReport', { tellCallerReport: [] });

    outboundCallerService
      .teleCallerReport({ query })
      .then(response => {
        commit('setTelleReport', { tellCallerReport: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  },
  async dayWiseOutboundReport({ dispatch, commit }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setDayWiseReport', { dayWiseReport: [] });

    outboundCallerService
      .outboundCallerReportDaywise({ query })
      .then(response => {
        commit('setDayWiseReport', { dayWiseReport: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  },
  async dayWiseTeleCallerReport({ dispatch, commit }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setTeleCallerDayWiseReport', { teleCallerDayWiseReport: [] });

    outboundCallerService
      .teleCallerReportDaywise({ query })
      .then(response => {
        commit('setTeleCallerDayWiseReport', { teleCallerDayWiseReport: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  },

  async downloadCallerReportLive({ dispatch, commit }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setCallerReport', { callerReportLive: [] });
    outboundCallerService
      .callerReportLive({ query })
      .then(response => {
        commit('setCallerReport', { callerReportLive: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  },

  setDownloadDialedListToEmpty({ commit }) {
    commit('setDownloadDialed', { downloadDialedCallList: [] });
  },
  async upload({ dispatch, commit }, { data, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('uploadLoading');
    commit('uploadLength', { lengthOfUpload: data.length });
    try {
      let abc = 0;
      if (data.length >= 5000) {
        const chunkSize = 5000;
        const chunks = _.chunk(data, chunkSize);
        for (let i = 0; i < chunks.length; ) {
          const response = await outboundCallerService.upload({ data: chunks[i] }); //eslint-disable-line
          if (response.status === 200) {
            i += 1;
            abc += 1;
            commit('uploadSize', { sizeOfUpload: abc });
          } else {
            throw new Error('Invalid Reponse');
          }
          await new Promise(r => setTimeout(r, 2000)); //eslint-disable-line
        }
      } else {
        await outboundCallerService.upload({ data });
      }
      dispatch(
        'alert/success',
        {
          showType: 'toast',
          position: 'center',
          title: '',
          type: 'success',
          text: 'Data uploaded successfully & BlackListed numbers has been removed !!'
        },
        { root: true }
      );
      router.go();
    } catch (e) {
      commit('requestFailed');
      dispatch('common/handleServiceException', { e }, { root: true });
    }
    commit('finishRequest');
  },

  blacklist({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setBlackListNumber', { blackListNumber: [], pagination: {} });
    commit('startRequest');
    outboundCallerService
      .blockedNumberList({ query })
      .then(response => {
        commit('setBlackListNumber', { blackListNumber: response.data.rows, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  deleteOne({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    outboundCallerService
      .deleteOne({ id })
      .then(_response => {
        dispatch('blacklist', { id });
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: ' Number has been deleted.'
          },
          { root: true }
        );
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  removeFromList({ dispatch, commit }, { fromPage = false }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    outboundCallerService
      .removeFromCallingList()
      .then(_response => {
        if (fromPage === true) {
          dispatch(
            'alert/success',
            {
              showType: 'toast',
              position: 'bottom',
              title: '',
              text: 'BlackListed Number has been Removed from list.'
            },
            { root: true }
          );
        } else {
          dispatch(
            'alert/success',
            {
              showType: 'toast',
              position: 'center',
              title: '',
              text: 'BlackListed Number has been Removed from list.'
            },
            { root: true }
          );
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
    commit('finishRequest');
  },
  async downloadBlacklistNumber({ dispatch, commit }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setDownloadBlacklistNumber', { downloadBlackListNumbers: [] });
    let stopDownload = false;
    const pageSize = 5000;
    const accData = [];

    for (let i = 0; !stopDownload; ) {
      try {
        // eslint-disable-next-line no-await-in-loop
        const response = await outboundCallerService.blockedNumberList({
          query: { ...query, page: i + 1, page_size: pageSize }
        });
        if (response.data.pagination.total_rows > 5000) {
          dispatch(
            'alert/success',
            {
              showType: 'toast',
              position: 'center',
              title: 'Preparing your download, please wait...'
            },
            { root: true }
          );
        }
        if (response.data.rows.length === 0) {
          stopDownload = true;
        }
        accData.push(...response.data.rows);
        i += 1;
      } catch (e) {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
        return;
      }
    }
    commit('setDownloadBlacklistNumber', { downloadBlackListNumbers: accData });
    commit('finishRequest');
  },
  async uploadBlacklist({ dispatch, commit }, { data, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    try {
      if (data.length >= 5000) {
        const chunkSize = 5000;
        const chunks = _.chunk(data, chunkSize);
        for (let i = 0; i < chunks.length; ) {
          const response = await outboundCallerService.uploadBlacklist({ data: chunks[i] }); //eslint-disable-line
          if (response.status === 200) {
            i += 1;
          } else {
            throw new Error('Invalid Reponse');
          }
        }
      } else {
        await outboundCallerService.uploadBlacklist({ data });
      }
      dispatch(
        'alert/success',
        {
          showType: 'toast',
          position: 'center',
          title: '',
          type: 'success',
          text: 'Data uploaded successfully !!'
        },
        { root: true }
      );
      dispatch('removeFromList', { fromPage: false });
      const response = await outboundCallerService.removeFromCallingList({ data });
      if (response.status === 200) {
        router.go();
      }
    } catch (e) {
      commit('requestFailed');
      dispatch('common/handleServiceException', { e }, { root: true });
    }
    commit('finishRequest');
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  startDownload(state, { startDownloading }) {
    state.startDownloading = startDownloading;
  },
  uploadLength(state, { lengthOfUpload }) {
    state.lengthOfUpload = lengthOfUpload;
  },
  uploadSize(state, { sizeOfUpload }) {
    state.sizeOfUpload = sizeOfUpload;
  },
  downloadBar(state, { totalDownloadSize }) {
    state.totalDownloadSize = totalDownloadSize + 2;
  },
  downloadProcess(state, { downloadProgress }) {
    state.downloadProgress = downloadProgress;
  },
  uploadLoading(state) {
    state.uploadLoading = true;
  },
  setNumberOfCaller(state, { numberList }) {
    state.numberList = numberList;
    state.loading = false;
  },
  setDeleteNumberCount(state, { deleteNumberCount }) {
    state.deleteNumberCount = deleteNumberCount;
    state.loading = false;
  },
  requestFailed(state) {
    state.loading = false;
  },
  finishRequest(state) {
    state.loading = false;
  },
  setOutboundCallers(state, { outboundCallers, pagination }) {
    let row = 1;
    state.outboundCallerList = outboundCallers.map(outboundCaller => {
      const { _id } = outboundCaller;
      const newOutboundCaller = new OutboundCaller({
        id: _id,
        rowNum: row,
        mobile: outboundCaller.mobile,
        storeCode: outboundCaller.storeCode,
        callingDate: outboundCaller.callingDate,
        createdAt: outboundCaller.createdAt,
        createdByName: outboundCaller.createdByName,
        priority: outboundCaller.priority ? outboundCaller.priority : '-',
        filterNew: outboundCaller.filterNew ? outboundCaller.filterNew : '-'
      });
      row += 1;
      return newOutboundCaller;
    });
    state.pagination = pagination;
    state.loading = false;
  },
  setBlackListNumber(state, { blackListNumber, pagination }) {
    let row = 1;
    state.blackListNumber = blackListNumber.map(blackListNumber => {
      const { _id } = blackListNumber;
      const newBlackListNumber = new BlackListNumber({
        id: _id,
        rowNum: row,
        mobile: blackListNumber.mobile,
        createdByName: blackListNumber.createdByName,
        createdAt: blackListNumber.createdAt
      });
      row += 1;
      return newBlackListNumber;
    });
    state.pagination = pagination;
    state.loading = false;
  },
  setDownloadOutboundCallers(state, { downloadOutboundCallerList }) {
    let row = 1;
    state.downloadOutboundCallerList = _.map(downloadOutboundCallerList, outboundCaller => {
      const { _id } = outboundCaller;
      const formattedOutboundCaller = new OutboundCaller({
        id: _id,
        rowNum: row,
        mobile: outboundCaller.mobile,
        storeCode: outboundCaller.storeCode,
        priority: outboundCaller.priority ? outboundCaller.priority : '-',
        callingDate: outboundCaller.callingDate,
        createdAt: outboundCaller.createdAt,
        createdByName: outboundCaller.createdByName,
        createdBy: outboundCaller.createdBy,
        filterNew: outboundCaller.filterNew ? outboundCaller.filterNew : '-'
      });
      row += 1;
      return formattedOutboundCaller;
    });
    state.loading = false;
  },
  setDownloadBlacklistNumber(state, { downloadBlackListNumbers }) {
    let row = 1;
    state.downloadBlackListNumbers = _.map(downloadBlackListNumbers, blackListNumber => {
      const { _id } = blackListNumber;
      const formattedBlacklistNumber = new BlackListNumber({
        id: _id,
        rowNum: row,
        mobile: blackListNumber.mobile,
        createdByName: blackListNumber.createdByName
      });
      row += 1;
      return formattedBlacklistNumber;
    });
  },
  setDialedCalls(state, { dialedCalls, pagination }) {
    let row = 1;
    state.dialedCallList = dialedCalls.map(dialedCall => {
      const { _id } = dialedCall;
      const newDialedCall = new OutboundCaller({
        id: _id,
        rowNum: row,
        mobile: dialedCall.mobile,
        storeCode: dialedCall.storeCode,
        callingDate: dialedCall.callingDate,
        customerName: dialedCall.customerName ? dialedCall.customerName : '-',
        type: dialedCall.type,
        callDuration: Math.floor(dialedCall.callDuration),
        manualDialed: dialedCall.manualDialed,
        isDialed: dialedCall.isDialed,
        address: dialedCall.address ? dialedCall.address : '-',
        lastDialedAt: dialedCall.lastDialedAt,
        callerName: dialedCall.callerName,
        createdAt: dialedCall.createdAt,
        createdByName: dialedCall.createdByName,
        priority: dialedCall.priority ? dialedCall.priority : '-',
        filterNew: dialedCall.filterNew ? dialedCall.filterNew : '-',
        orderCreatedAt: dialedCall.orderCreatedAt,
        orderConverted: dialedCall.orderCreatedAt !== null ? 'Yes' : 'No',
        customerDisposition: dialedCall.customerDisposition ? dialedCall.customerDisposition : '-'
      });
      row += 1;
      return newDialedCall;
    });
    state.pagination = pagination;
    state.loading = false;
  },
  setDownloadDialed(state, { downloadDialedCallList }) {
    let row = 1;
    state.downloadDialedCallList = _.map(downloadDialedCallList, dialedCall => {
      const { _id } = dialedCall;
      const formattedDialedCall = new OutboundCaller({
        id: _id,
        rowNum: row,
        mobile: dialedCall.mobile,
        storeCode: dialedCall.storeCode,
        callingDate: dialedCall.callingDate,
        customerName: dialedCall.customerName ? dialedCall.customerName : '-',
        type: dialedCall.type,
        callDuration: Math.floor(dialedCall.callDuration),
        manualDialed: dialedCall.manualDialed,
        isDialed: dialedCall.isDialed,
        address: dialedCall.address ? dialedCall.address : '-',
        lastDialedAt: dialedCall.lastDialedAt,
        callerName: dialedCall.callerName,
        createdAt: dialedCall.createdAt,
        createdByName: dialedCall.createdByName,
        priority: dialedCall.priority ? dialedCall.priority : '-',
        filterNew: dialedCall.filterNew ? dialedCall.filterNew : '-',
        orderCreatedAt: dialedCall.orderCreatedAt,
        orderConverted: dialedCall.orderCreatedAt !== null ? 'Yes' : 'No',
        customerDisposition: dialedCall.customerDisposition ? dialedCall.customerDisposition : '-'
      });
      row += 1;
      return formattedDialedCall;
    });
    state.loading = false;
  },
  setOutboundReport(state, { outboundReport, pagination }) {
    state.outboundReport = outboundReport;
    state.pagination = pagination;
  },
  setTelleReport(state, { tellCallerReport, pagination }) {
    state.tellCallerReport = tellCallerReport;
    state.pagination = pagination;
  },
  setDayWiseReport(state, { dayWiseReport, pagination }) {
    state.dayWiseReport = dayWiseReport;
    state.pagination = pagination;
  },
  setTeleCallerDayWiseReport(state, { teleCallerDayWiseReport, pagination }) {
    state.teleCallerDayWiseReport = teleCallerDayWiseReport;
    state.pagination = pagination;
  },
  setCallerReport(state, { callerReportLive, pagination }) {
    state.callerReportLive = callerReportLive;
    state.pagination = pagination;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
