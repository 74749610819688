import Vue from 'vue';
import VueMeta from 'vue-meta';
import Vuelidate from 'vuelidate';
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import VueSweetalert2 from 'vue-sweetalert2';
import VueSocketIO from 'vue-socket.io';
import SocketIO from 'socket.io-client';
import '@sweetalert2/theme-bootstrap-4/bootstrap-4.scss';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faPortrait,
  faTrash,
  faPlus,
  faCheckSquare,
  faSquare,
  faUser,
  faTachometerAlt,
  faCog,
  faUserCircle,
  faSignOutAlt,
  faEdit,
  faTrashAlt,
  faLongArrowAltLeft,
  faSave,
  faListAlt,
  faChartPie,
  faDownload,
  faUpload,
  faFilter,
  faClipboardList,
  faSms,
  faQuestionCircle,
  faRecordVinyl,
  faPhone,
  faAngleDoubleLeft,
  faArrowRight,
  faMinus,
  faPlay,
  faShare,
  faSearch,
  faStore,
  faUsers,
  faChartLine,
  faEye,
  faCopy,
  faEnvelope,
  faCalendar,
  faTicketAlt,
  faMapMarkerAlt,
  faCartPlus,
  faSort,
  faRetweet
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';
import ConfigService from '@/services/configService';

import store from '@/store';
import App from './App.vue';
import router from './router';
import './registerServiceWorker';
import './assets/css/style.css';
import SimpleLayout from './views/Layout/SimpleLayout.vue';
import BackendLayout from './views/Layout/BackendLayout.vue';
import ECatalogueLayout from './views/Layout/ECatalogueLayout.vue';

Vue.config.productionTip = false;

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
});
Vue.use(Vuelidate);
Vue.use(BootstrapVue);
Vue.use(VueSweetalert2, {});
Vue.component('datetime', Datetime);

Vue.use(
  new VueSocketIO({
    debug: true,
    connection: SocketIO(
      process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : 'https://spencer-apiv1.attabot.in'
    ), // options object is Optional
    vuex: {
      store,
      actionPrefix: 'SOCKET_',
      mutationPrefix: 'SOCKET_'
    }
  })
);

library.add(
  faPortrait,
  faTrash,
  faTrashAlt,
  faPlus,
  faCheckSquare,
  faSquare,
  faUser,
  faTachometerAlt,
  faCog,
  faPlay,
  faUserCircle,
  faSignOutAlt,
  faEdit,
  faLongArrowAltLeft,
  faSave,
  faListAlt,
  faChartPie,
  faDownload,
  faUpload,
  faFilter,
  faClipboardList,
  faSms,
  faQuestionCircle,
  faRecordVinyl,
  faPhone,
  faSearch,
  faAngleDoubleLeft,
  faArrowRight,
  faMinus,
  faShare,
  faPlus,
  faStore,
  faUsers,
  faChartLine,
  faEye,
  faCopy,
  faEnvelope,
  faCalendar,
  faTicketAlt,
  faMapMarkerAlt,
  faCartPlus,
  faSort,
  faRetweet
);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('simple-layout', SimpleLayout);
Vue.component('backend-layout', BackendLayout);
Vue.component('e-catalogue-layout', ECatalogueLayout);

ConfigService.loadConfig().then(() => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app');
});
