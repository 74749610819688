import _ from 'lodash';
import userService from '@/services/userService';
import User from '../../model/user';
import InboundCaller from '../../model/inboundCaller';

const state = {
  baseUrl: '/staff',
  users: [],
  newUsers: [],
  activeReport: [],
  inboundList: [],
  pagination: {},
  user: null,
  loading: false,
  availableRoles: []
};

const actions = {
  list({ dispatch, commit }, { type = 'user', query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setUsers', { users: [], pagination: {} });

    commit('startRequest');

    userService
      .list({ type, query })
      .then(response => {
        commit('setUsers', { users: response.data.rows, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },

  async downloadUsers({ dispatch, commit }, { type = 'user', query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });

    commit('startRequest');
    // commit('setNewUsers', { newUsers: [], pagination: null });

    let stopDownload = false;
    const pageSize = 50;
    const accData = [];

    for (let i = 0; !stopDownload; ) {
      try {
        // eslint-disable-next-line no-await-in-loop
        const response = await userService.list({
          type,
          query: { ...query, page: i + 1, page_size: pageSize }
        });

        if (response.data.pagination.total_rows > 50) {
          dispatch(
            'alert/success',
            {
              showType: 'toast',
              position: 'center',
              title: 'Preparing your download, please wait...'
            },
            { root: true }
          );
        }
        if (response.data.rows.length === 0) {
          stopDownload = true;
        }
        accData.push(...response.data.rows);
        i += 1;
      } catch (e) {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
        return;
      }
    }
    commit('setNewUsers', { newUsers: accData, pagination: null });
  },
  listCaller({ dispatch, commit }, { type = 'user', query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setInboundNumbers', { inboundList: [] });

    userService
      .listInboundCaller({ type, query })
      .then(response => {
        commit('setInboundNumbers', { inboundList: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  getOne({ dispatch, commit }, { type = 'staff', mobile, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setUser', { user: {} });

    userService
      .getOne({ type, mobile })
      .then(response => {
        commit('setUser', { user: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  postOne({ dispatch, commit }, { type = 'user', user, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    userService
      .postOne({ type, user })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: type === 'user' ? 'New user has been added.' : 'New staff has been added.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  patchOne({ dispatch, commit }, { type = 'user', mobile, user, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    userService
      .patchOne({ type, mobile, newUser: user })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: type === 'user' ? 'User has been updated.' : 'Staff has been updated.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  passwordChange({ dispatch, commit }, { type = 'user', mobile, passwordOld, passwordNew, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    userService
      .passwordChange({ type, mobile, passwordOld, passwordNew })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'Your password has been changed.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  passwordReset({ dispatch, commit }, { query, router, password, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    userService
      .passwordReset({ query, passwordNew: password })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'Your password has been changed.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  getOtp({ dispatch, commit }, { type = 'user', mobile, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    userService
      .getOtp({ type, mobile })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'OTP has been sent to your mobile.'
          },
          { root: true }
        );
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  deleteOne({ dispatch, commit }, { type = 'user', mobile, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    userService
      .deleteOne({ type, mobile })
      .then(_response => {
        dispatch('list', { type });
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: type === 'user' ? 'User has been deleted.' : 'Staff has been deleted.'
          },
          { root: true }
        );
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  getRoles({ dispatch, commit }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    userService
      .getRoles()
      /* eslint no-underscore-dangle: 0 */
      .then(response =>
        commit(
          'setRoles',
          response.data.map(item => ({ name: item.name, id: item._id }))
        )
      )
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  },
  downloadReport({ dispatch, commit }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    userService
      .downloadReport()
      .then(_response => {
        commit('setActiveReport', { activeReport: _response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  setUsers(state, { users, pagination }) {
    let row = pagination ? pagination.first_row_no : 1;
    state.users = _.map(users, user => {
      const newUser = new User({
        id: row,
        mobile: user.user.mobile,
        name: user.user.name,
        role: user.user.role,
        roles: user.user.roles ? user.user.roles : [],
        status: user.user.status,
        type: user.user.type,
        storeid: user.user.storeids ? user.user.storeids[0] : null,
        storeids: user.user.storeids,
        lastLogin: user.user.lastLogin,
        empid: user.user.empid,
        designation: user.user.designation,
        pincode: user.storeInfo.pincode,
        nearbyPincode: user.storeInfo.nearby_pincode,
        storeManagerNumber: user.storeInfo.store_manager_number,
        smsNumber: user.storeInfo.sms_number
      });
      row -= 1;
      return newUser;
    });
    state.pagination = pagination;
    state.loading = false;
  },
  setInboundNumbers(state, { inboundList }) {
    let row = 1;
    state.inboundList = _.map(inboundList, list => {
      const { _id, lastStoreId, lastName, lastEmpId } = list;
      const inbound = new InboundCaller({
        row,
        mobile: _id,
        storeId: lastStoreId,
        name: lastName,
        empId: lastEmpId
      });
      row += 1;
      return inbound;
    });
    state.loading = false;
  },
  setUser(state, { user }) {
    const { _id } = user;
    state.user = new User({
      id: _id,
      mobile: user.mobile,
      name: user.name,
      role: user.role,
      status: user.status,
      type: user.type,
      roles: user.roles ? user.roles : [],
      clusters: user.clusters ? user.clusters : [],
      regions: user.regions ? user.regions : [],
      storeid: user.storeids ? user.storeids[0] : null,
      storeids: user.storeids,
      empid: user.empid,
      designation: user.designation
    });
    state.loading = false;
  },
  setRoles(state, availableRoles) {
    state.availableRoles = availableRoles;
    state.loading = false;
  },
  setActiveReport(state, { activeReport }) {
    state.activeReport = activeReport;
    state.loading = false;
  },
  setNewUsers(state, { newUsers, pagination }) {
    let row = pagination ? pagination.first_row_no : 1;
    state.newUsers = _.map(newUsers, user => {
      const newUser = new User({
        id: row,
        mobile: user.user.mobile,
        name: user.user.name,
        role: user.user.role,
        roles: user.user.roles ? user.user.roles : [],
        status: user.user.status,
        type: user.user.type,
        storeid: user.user.storeids ? user.user.storeids[0] : null,
        storeids: user.user.storeids,
        lastLogin: user.user.lastLogin,
        empid: user.user.empid,
        designation: user.user.designation,
        pincode: user.storeInfo.pincode,
        nearbyPincode: user.storeInfo.nearby_pincode,
        storeManagerNumber: user.storeInfo.store_manager_number,
        smsNumber: user.storeInfo.sms_number
      });
      row += 1;
      return newUser;
    });
    state.pagination = pagination;
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
