import moment from 'moment';

export default class Target {
  constructor({ id, rowNum, target, targetSales, date, storeid, createdAt, submittedBy }) {
    this.id = id || '';
    this.rowNum = rowNum || '';
    this.storeid = storeid || '';
    this.target = target || '';
    this.targetSales = targetSales || '';
    this.date = moment(date).format('DD-MMM-YYYY');
    this.createdAt = moment(createdAt).format('DD-MMM-YYYY');
    this.submittedBy = submittedBy || '';
  }
}
