import moment from "moment";

export default class OrderPosMis {
  constructor({
    id,
    customerMobile,
    storeid,
    storeName,
    createdAt,
    articleCode,
    articleDescription,
    quantity,
    UOM,
    itemCategory,
    amount,
    otpGeneratedTime,
    otpFeedTime,
    delivered,
    rowNum,
    posItems,
    billNumber,
    tillNumber,
    orderCreateLocation,
    orderDeliveredLocation,
    itemCode,
    coupon

  }) {
    this.id = id || "";
    this.rowNum = rowNum || "";
    this.customerMobile = customerMobile;
    this.billNumber = billNumber || "NA";
    this.tillNumber = tillNumber || "NA";
    this.createdAt = createdAt ? moment(createdAt).format('DD-MMM-YYYY   hh:mm  A') : null
    this.articleCode = articleCode || '';
    this.articleDescription = articleDescription || '';
    this.quantity = quantity || '';
    this.UOM = UOM || '';
    this.itemCategory = itemCategory || '';
    this.amount = amount || '';
    this.storeName = storeName || "";
    this.storeid = storeid || "";
    this.otpGeneratedTime = otpGeneratedTime ? moment(otpGeneratedTime).format('DD-MMM hh:mm  A') : 'NA';
    this.otpFeedTime = otpFeedTime ? moment(otpFeedTime).format('DD-MMM hh:mm  A') : 'NA';
    this.delivered = delivered || "";
    this.posItems = posItems || [];
    this.orderCreateLocation = orderCreateLocation || '';
    this.orderDeliveredLocation = orderDeliveredLocation || '';
    this.itemCode = itemCode || '';
    this.coupon = coupon || '';
  }
}
