import _ from 'lodash';
import axios from 'axios';
import configService from '@/services/configService';
import utils from '@/helper/utils';

export default {
  async list({ query = {} } = {}) {
    const pickedQuery = _.pick(query, ['q', 'search', 'searchkey', 'startdate', 'enddate']);
    let url = `${configService.get('apiUrl')}/bTobReport`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }
    return axios
      .get(url, {})

      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },

  async b2BStoreList() {
    const url = `${configService.get('apiUrl')}/bTobReportStores`;

    return axios
      .get(url, {})

      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  }
};
