export default class SalesReport {
  constructor({
    _id,
    totalNOB,
    totalSale,
    abv,
    storeFormat,
    storeRegion,
    storeCluster,
    storeName,
    index = 0

  }) {
    this.index = index + 1;
    this.storeRegion = storeRegion || '';
    this.storeCluster = storeCluster || '';
    this.storeName = storeName || '';
    this._id = _id || ''; // eslint-disable-line
    this.storeFormat = storeFormat || '';
    this.totalNOB = totalNOB || '';
    this.totalSale = totalSale || '';
    this.abv = abv || '';
  }
}
