import _ from 'lodash';
import axios from 'axios';
import configService from '@/services/configService';
import utils from '@/helper/utils';

export default {
  async listProducts({ query = {} } = {}) {
    const pickedQuery = _.pick(query, []);
    let url = `${configService.get('apiUrl')}/product/list-byGroup`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async listProductsByCategory({ query = {} } = {}) {
    const pickedQuery = _.pick(query, ['category', 'subCategory', 'q']);
    let url = `${configService.get('apiUrl')}/product/listByCategory`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async addProduct({ product }) {
    const url = `${configService.get('apiUrl')}/product`;

    return axios
      .post(url, product)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async deleteProduct({ productId }) {
    return axios
      .delete(`${configService.get('apiUrl')}/product/${productId}`)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async editProduct({ product, productId }) {
    const url = `${configService.get('apiUrl')}/product/${productId}`;

    return axios
      .patch(url, product)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },

  async approveProduct({ productIds, active }) {
    const url = `${configService.get('apiUrl')}/product/approve`;
    return axios
      .post(url, { productIds, active })
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async uploadProduct({ data } = {}) {
    return axios
      .post(`${configService.get('apiUrl')}/product/upload`, { products: data })
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },

  async listproductPreview() {
    const url = `${configService.get('apiUrl')}/product/preview`;
    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async listByCustomer({ query = {} } = {}) {
    const pickedQuery = _.pick(query, ['q', 'startdate', 'enddate', 'storeId']);
    let url = `${configService.get('apiUrl')}/product`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async listStore({ query = {} } = {}) {
    const pickedQuery = _.pick(query, ['q', 'pincode']);
    let url = `${configService.get('apiUrl')}/store/pincode`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async postOne({ order } = {}) {
    return axios
      .post(`${configService.get('apiUrl')}/order/open`, order)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async postOneECatalogue({ order } = {}) {
    return axios
      .post(`${configService.get('apiUrl')}/order/b2b-open`, order)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async getOffer({ articleId, storeId }) {
    return axios
      .get(`${configService.get('apiUrl')}/getOfferByArticleId?articleId=${articleId}&storeId=${storeId}`, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async deleteCatalogue({ catalogueName }) {
    return axios
      .delete(`${configService.get('apiUrl')}/deleteProducts?cataGroup=${catalogueName}`)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },

  async listOrders({ query = {} } = {}) {
    const url = `${configService.get('apiUrl')}/catalogueOrders`;
    return axios
      .get(url, { params: query })
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },

  async listCategory({ query = {} } = {}) {
    const pickedQuery = _.pick(query, []);
    let url = `${configService.get('apiUrl')}/eCatalogueCategory`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },

  async addCategory({ category }) {
    const url = `${configService.get('apiUrl')}/eCatalogueCategory`;

    return axios
      .post(url, category)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },

  async deleteCategory({ categoryId }) {
    return axios
      .delete(`${configService.get('apiUrl')}/eCatalogueCategory/${categoryId}`)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },

  async editCategory({ category, categoryId }) {
    const url = `${configService.get('apiUrl')}/eCatalogueCategory/${categoryId}`;

    return axios
      .patch(url, category)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  }
};
