import moment from 'moment';

export default class OutboundLog {
  constructor({
    id,
    customerName,
    callDuration,
    status,
    callStatus,
    feedback,
    customerMobile,
    storeid,
    createdAt,
    updatedBy
  }) {
    this.id = id || '1';
    this.customerName = customerName || null;
    this.customerMobile = customerMobile || null;
    this.status = status || null;
    this.callStatus = callStatus || null;
    this.callDuration = callDuration || 0;
    this.storeid = storeid || null;
    this.feedback = feedback || null;
    this.createdAt = moment(createdAt).format('LLL');
    this.updatedBy = updatedBy || null;
  }
}
