import customerMetaService from '../../services/customerMetaService';
import CustomerData from '../../model/customerMetaData';

const state = {
  baseUrl: '/customerinfo',
  customerData: {},
  loading: false
};

const actions = {
  listCustomerMetaData({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setCustomerData', { customerData: {} });

    customerMetaService
      .listCustomerMetaData({ query })
      .then(response => {
        commit('setCustomerData', { customerData: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },

  setCustomerData(state, { customerData }) {
    state.customerData = new CustomerData({
      mobile: customerData.mobile,
      name: customerData.name,
      address: customerData.address
    });
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
