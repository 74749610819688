import moment from 'moment';

export default class EcommOrder {
  constructor({
    id,
    orderId,
    name,
    address,
    storeid,
    customerNumber,
    customerName,
    customerEmail,
    createdAt,
    deliveryType,
    orderIncrementId,
    orderTotal,
    paymentMode,
    quoteId,
    updatedAt,
    orderStatus,
    source
  }) {
    this.id = id;
    this.orderId = orderId;
    this.name = name;
    this.address = address;
    this.storeid = storeid || null;
    this.customerNumber = customerNumber || '-';
    this.customerName = customerName || '-';
    this.customerEmail = customerEmail || '-';
    this.createdAt = createdAt ? moment(createdAt).format('DD-MM-YYYY hh:mm A') : null;
    this.deliveryType = deliveryType || '-';
    this.orderIncrementId = orderIncrementId || '-';
    this.orderTotal = orderTotal || 0;
    this.paymentMode = paymentMode || '-';
    this.quoteId = quoteId || '-';
    this.orderStatus = orderStatus || 'IN PROGRESS';
    this.updatedAt = updatedAt ? moment(updatedAt).format('DD-MM-YYYY hh:mm A') : null;
    this.source = source || '';
  }
}
