import _ from 'lodash';
import axios from 'axios';
import configService from '@/services/configService';
import utils from '@/helper/utils';

export default {
  async listNob({ query = {} } = {}) {
    const pickedQuery = _.pick(query, ['q', 'startdate', 'enddate']);
    let url = `${configService.get('apiUrl')}/sales/category-wise/nob`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async listSale({ query = {} } = {}) {
    const pickedQuery = _.pick(query, ['q', 'startdate', 'enddate']);
    let url = `${configService.get('apiUrl')}/sales/category-wise/sale`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  }
};
