export default class ProductList {
  constructor({ id, active, products, isPublic, catalogueTitle, storeid, storeName }) {
    this.id = id || '';
    this.active = active || '';
    this.products = products || '';
    this.isPublic = isPublic || '';
    this.catalogueTitle = catalogueTitle || '';
    this.storeid = storeid || '';
    this.storeName = storeName || '';
  }
}
