import _ from 'lodash';
import axios from 'axios';
import configService from '@/services/configService';
import utils from '@/helper/utils';

export default {
  async list({ query = {} } = {}) {
    const pickedQuery = _.pick(query, [
      'q',
      'page',
      'page_size',
      'filter',
      'search',
      'searchkey',
      'startdate',
      'enddate'
    ]);
    let url = `${configService.get('apiUrl')}/order`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },

  async listByCustomer({ customerMobile }) {
    return axios
      .get(`${configService.get('apiUrl')}/order/customer/${customerMobile}/`, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },

  async getOne({ id }) {
    return axios
      .get(`${configService.get('apiUrl')}/order/${id}/`, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },

  async postOne({ order } = {}) {
    return axios
      .post(`${configService.get('apiUrl')}/order/`, order)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },

  async patchOne({ orderId, order }) {
    return axios
      .patch(`${configService.get('apiUrl')}/order/${orderId}`, order)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },

  async deleteOne({ id }) {
    return axios
      .delete(`${configService.get('apiUrl')}/order/${id}`)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },

  async upload({ data } = {}) {
    return axios
      .post(`${configService.get('apiUrl')}/order/upload`, { data })
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async aptReportList({ query = {} } = {}) {
    const pickedQuery = _.pick(query, [
      'q',
      'page',
      'page_size',
      'filter',
      'search',
      'searchkey',
      'startdate',
      'enddate'
    ]);
    let url = `${configService.get('apiUrl')}/orderAptReport`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }
    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async checkStoreAvailability({ storeid, pincode }) {
    return axios
      .post(`${configService.get('apiUrl')}/ecomm-order-check-store`, { storeId: storeid, pinCode: pincode })
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },

  async checkStockAvailability({ params } = {}) {
    return axios
      .post(`${configService.get('apiUrl')}/ecomm-order-check-product-availability`, {
        items: params.items,
        storeId: params.storecode
      })
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },

  async postOrderMagento({ params } = {}) {
    return axios
      .post(`${configService.get('apiUrl')}/ecomm-order-post-order`, {
        ...params
      })
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async ecommOrderList({ query = {} } = {}) {
    const pickedQuery = _.pick(query, [
      'q',
      'page',
      'page_size',
      'filter',
      'search',
      'searchkey',
      'startdate',
      'enddate',
      'source'
    ]);
    let url = `${configService.get('apiUrl')}/ecomm-order`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async getOneOrderMagento({ id }) {
    return axios
      .get(`${configService.get('apiUrl')}/ecomm-order/${id}/`, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async getStateAndStateCode() {
    const url = `${configService.get('apiUrl')}/get-state-code`;

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async onlineCustomerData({ customerMobile }) {
    const url = `${configService.get('apiUrl')}/order/online-customer/${customerMobile}`;

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async findAddress({ search }) {
    return axios
      .post(`${configService.get('apiUrl')}/search-google-address`, { search })
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  }
};
