import moment from 'moment';

export default class Order {
  constructor({
    id,
    orderId,
    name,
    customerMobile,
    address,
    salesAssociateName,
    employeeId,
    gstNumber,
    description,
    dateCreated,
    dateUpdated,
    products,
    storeid,
    source,
    isAcknowledged,
    isDelivered,
    createdAt,
    acknowledgedTime,
    packagedTime,
    posPingTime,
    posItems,
    modeOfPayment,
    pinCode,
    billNumber,
    orderMode,
    deliveryCode,
    // UOM,
    // amount,
    // disc,
    // hsnCode,
    // itemCategory,
    // itemName,
    // mrp,
    // quantity,
    actualDeliveredTime,
    imageURL,
    deliveryPics
    // orderCreateLocation,
    // orderDeliveredLocation
  }) {
    this.id = id;
    this.orderId = orderId;
    this.name = name;
    this.address = address;
    this.salesAssociateName = salesAssociateName;
    this.employeeId = employeeId;
    this.gstNumber = gstNumber;
    this.source = source || 'SINGLE_FORM_ENTRY';
    this.customerMobile = customerMobile;
    this.dateCreated = moment(dateCreated).format('DD-MM-YYYY hh:mm  A');
    this.dateUpdated = moment(dateUpdated).format('DD-MM-YYYY hh:mm  A');
    this.description = description;
    this.storeid = storeid || null;
    this.isAcknowledged = isAcknowledged || false;
    this.isDelivered = isDelivered || false;
    this.products = products ? products.join(',') : null;
    this.createdAt = createdAt ? moment(createdAt).format('DD-MM-YYYY hh:mm A') : null;
    this.acknowledgedTime = acknowledgedTime ? moment(acknowledgedTime).format('DD-MM-YYYY hh:mm  A') : null;
    this.packagedTime = packagedTime ? moment(packagedTime).format('DD-MM-YYYY hh:mm  A') : null;
    this.posPingTime = posPingTime ? moment(posPingTime).format('DD-MM-YYYY hh:mm  A') : null;
    this.actualDeliveredTime = actualDeliveredTime ? moment(actualDeliveredTime).format('DD-MM-YYYY hh:mm A') : null;
    // this.UOM = UOM || null;
    // this.amount = amount || null;
    // this.disc = disc || null;
    // this.hsnCode = hsnCode || null;
    // this.itemCategory = itemCategory || null;
    // this.itemName = itemName || null;
    // this.mrp = mrp || null;
    // this.quantity = quantity || null;
    this.orderMode = orderMode || '';
    this.pinCode = pinCode || '';
    this.posItems = posItems || '';
    this.imageURL = imageURL || '';
    this.deliveryPics = deliveryPics || '';
    this.modeOfPayment = modeOfPayment || '';
    this.billNumber = billNumber || '';
    this.deliveryCode = deliveryCode || '-';
    // this.orderCreateLocation = orderCreateLocation || '';
    // this.orderDeliveredLocation = orderDeliveredLocation || '';
  }
}
