import moment from 'moment';

export default class OutboundCaller {
  constructor({
    id,
    rowNum,
    customerName,
    mobile,
    type,
    callDuration,
    callingDate,
    manualDialed,
    isDialed,
    address,
    storeCode,
    lastDialedAt,
    callerName,
    createdByName,
    createdAt,
    createdBy,
    priority,
    filterNew,
    orderCreatedAt,
    orderConverted,
    customerDisposition
  }) {
    this.id = id || '';
    this.rowNum = rowNum || '';
    this.mobile = mobile || '';
    this.storeCode = storeCode || '';
    this.customerName = customerName || '';
    this.address = address || '';
    this.callDuration = callDuration || '';
    this.lastDialedAt = lastDialedAt
      ? moment(lastDialedAt)
          .utcOffset('+5:30')
          .format('DD/MM/YYYY hh:mm:ss A')
      : '';
    this.callerName = callerName || '';
    this.callingDate = callingDate ? moment(callingDate).format('DD/MM/YYYY') : '';
    this.isDialed = isDialed;
    this.createdAt = moment(createdAt).format('DD-MM-YYYY');
    this.createdByName = createdByName || '';
    this.createdBy = createdBy || '';
    this.manualDialed = manualDialed;
    this.type = type || '';
    this.priority = priority || '';
    this.filterNew = filterNew || '';
    this.orderCreatedAt = orderCreatedAt
      ? moment(orderCreatedAt)
          .utcOffset('+5:30')
          .format('DD/MM/YYYY hh:mm:ss A')
      : '';
    this.orderConverted = orderConverted || '';
    this.customerDisposition = customerDisposition || '';
  }
}
