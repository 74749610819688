import moment from "moment";

export default class Banner {
  constructor({
    id,
    rowNum,
    title,
    active,
    startDate,
    endDate,
    storeids,
    regions,
    clusters,
    formats,
    createdAt
  }) {
    this.id = id || '';
    this.rowNum = rowNum || '';
    this.title = title || '';
    this.active = active || '';
    this.startDate = moment(startDate).format('DD-MMM-YYYY');
    this.endDate = moment(endDate).format('DD-MMM-YYYY');
    this.storeids = storeids || '';
    this.regions = regions || '';
    this.clusters = clusters || '';
    this.formats = formats || '';
    this.createdAt = moment(createdAt).format('DD-MMM-YYYY');
  }
}
