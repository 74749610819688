import _ from 'lodash';
import salesReportService from '../../services/salesReportService';
import SalesReport from '../../model/salesReport';


const state = {
  baseUrl: '/order/analytics',
  reportList: [],
  loading: false,
  downloadReport: []
}

const actions = {

  list({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    salesReportService
      .list({ query })
      .then(response => {
        Object.keys(response.data).forEach(key => {
          commit('setreportList', { reportList: response.data[key].ListByStore });
        })
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  download({ dispatch, commit }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setDownloadReport', { downloadReport: [] });
    commit('startRequest');


    salesReportService
      .list({ query })
      .then(response => {
        Object.keys(response.data).forEach(key => {
          commit('setDownloadReport', { downloadReport: response.data[key].ListByStore });
        })
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  },
  setDownloadListToEmpty({ commit }) {
    commit('setDownloadReport', { downloadReport: [] });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  finishRequest(state) {
    state.loading = false;
  },
  setDownloadReport(state, { downloadReport }) {
    state.downloadReport = _.map(downloadReport, (report, index) => {
      const formattedData = new SalesReport({
        index,
        _id: report._id, // eslint-disable-line
        totalNOB: report.totalNOB || '',
        totalSale: report.totalSale || '',
        abv: report.abv || '',
        storeFormat: report.storeFormat || '',
        storeRegion: report.storeRegion || '',
        storeCluster: report.storeCluster || '',
        storeName: report.storeName || ''
      });
      return formattedData;
    });
    state.loading = false;
  },
  setreportList(state, { reportList }) {
    state.reportList = _.map(reportList, (report, index) => {
      const formattedData = new SalesReport({
        index,
        _id: report._id, // eslint-disable-line
        totalNOB: report.totalNOB || '',
        totalSale: report.totalSale || '',
        abv: report.abv || '',
        storeFormat: report.storeFormat || '',
        storeRegion: report.storeRegion || '',
        storeCluster: report.storeCluster || '',
        storeName: report.storeName || ''
      });
      return formattedData;
    });
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
