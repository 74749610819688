import _ from 'lodash';
import axios from 'axios';
import configService from '@/services/configService';
import utils from '@/helper/utils';

export default {
  async listCustomerMetaData({ query = {} } = {}) {
    const pickedQuery = _.pick(query, [' q', 'mobile']);
    let url = `${configService.get('apiUrl')}/customerinfo`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url, {
        headers: {
          authtoken: '92d368ec-e27e-427b-bc16-850a62d49bee'
        }
      })
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  }
};
