import moment from 'moment';

export default class BlackListNumber {
  constructor({ id, mobile, createdByName, rowNum, createdAt }) {
    this.id = id || '';
    this.rowNum = rowNum || '';
    this.mobile = mobile || '';
    this.createdByName = createdByName || '';
    this.createdAt = moment(createdAt).format('DD-MMM-YYYY');
  }
}
