import moment from "moment";

export default class Analytics {

  constructor({
    id,
    callerId,
    step,
    pincode,
    time,
    storeid,
    duration,
    managerNumber
  }) {
    this.id = id || '1';
    this.callerId = callerId;
    this.step = step;
    this.pincode = pincode;
    this.storeid = storeid;
    this.duration = duration;
    this.managerNumber = managerNumber;
    this.time = moment(time).format('DD-MM-YYYY hh:mm  A');
  }
}
