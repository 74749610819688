import _ from 'lodash';
import axios from 'axios';
import configService from '@/services/configService';
import utils from '@/helper/utils';

export default {
  async list({ query = {} } = {}) {
    let pickedQuery = _.pick(query, [
      'q',
      'page',
      'page_size',
      'filter',
      'search',
      'searchkey',
      'startdate',
      'enddate'
    ]);
    let url = `${configService.get('apiUrl')}/analytics`;
    pickedQuery = { ...pickedQuery, isLogs: false };
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async postFeedback({ query = {} } = {}) {
    const url = `${configService.get('apiUrl')}/analytics`;
    return axios
      .put(url, query)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async report({ query = {} } = {}) {
    let pickedQuery = _.pick(query, [
      'q',
      'page',
      'page_size',
      'filter',
      'search',
      'searchkey',
      'startdate',
      'enddate'
    ]);
    let url = `${configService.get('apiUrl')}/ivrReport`;
    pickedQuery = { ...pickedQuery, isLogs: false };
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  }
};
