import _ from 'lodash';
import orderPosMisService from '../../services/orderPosMisService';
import OrderPosMis from '../../model/orderPosMis';

const state = {
  baseUrl: '/order/pos',
  posList: [],
  pagination: {},
  loading: false,
  downloadPosMis: []
};

const actions = {
  list({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setPosList', { posList: [], pagination: null });

    commit('startRequest');

    orderPosMisService
      .list({ query })
      .then(response => {
        commit('setPosList', { posList: response.data.rows, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  async download({ dispatch, commit }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setDownloadPosMis', { downloadPosMis: [], pagination: null });
    commit('startRequest');
    dispatch(
      'alert/success',
      {
        showType: 'toast',
        position: 'center',
        title: '',
        type: 'success',
        text: 'Preparing your download....'
      },
      { root: true }
    );

    let stopDownload = false;
    const pageSize = 5000;
    const accData = [];

    for (let i = 0; !stopDownload;) {
      try {
        // eslint-disable-next-line no-await-in-loop
        const response = await orderPosMisService.list({
          query: { ...query, page: i + 1, page_size: pageSize }
        });
        if (response.data.pagination.total_rows > 5000) {
          dispatch(
            'alert/success',
            {
              showType: 'toast',
              position: 'center',
              title: '',
              type: 'success',
              text: 'Preparing your download....'
            },
            { root: true }
          );
        }
        if (response.data.rows.length === 0) {
          stopDownload = true;
        }
        accData.push(...response.data.rows);
        i += 1;
      } catch (e) {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
        return;
      }
    }
    commit('setDownloadPosMis', { downloadPosMis: accData, pagination: null });
  },
  setDownloadListToEmpty({ commit }) {
    commit('setDownloadPosMis', { downloadPosMis: [] });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  finishRequest(state) {
    state.loading = false;
  },
  setDownloadPosMis(state, { downloadPosMis }) {
    let row = 1;
    state.downloadPosMis = _.map(downloadPosMis, order => {
      const { _id } = order;
      const formattedData = new OrderPosMis({
        rowNum: row,
        id: _id,
        articleCode: order.posItems.hsnCode || '',
        articleDescription: order.posItems.itemName || '',
        quantity: order.posItems.quantity || '',
        UOM: order.posItems.UOM || '',
        itemCategory: order.posItems.itemCategory || 'NA',
        amount: order.posItems.amount || '',
        storeName: order.storeName,
        storeid: order.storeid,
        otpGeneratedTime: order.posPingTime ? order.posPingTime : '',
        otpFeedTime: order.actualDeliveredTime ? order.actualDeliveredTime : '',
        delivered: order.actualDeliveredTime ? 'Yes' : 'No',
        customerMobile: order.customerMobile,
        createdAt: order.createdAt,
        posItems: order.posItems,
        billNumber: order.billNumber ? order.billNumber : 'NA',
        tillNumber: order.tillNumber ? order.tillNumber : 'NA',
        orderCreateLocation: order.orderCreateLocation,
        orderDeliveredLocation: order.orderDeliveredLocation,
        itemCode: order.posItems.itemCode ? order.posItems.itemCode.replace(/^0+/, '') : 'NA',
        coupon: order.coupon ? order.coupon : 'NA'
      });
      row += 1;
      return formattedData;
    });
    state.loading = false;
  },
  setPosList(state, { posList, pagination }) {
    let row = 1;
    state.posList = _.map(posList, order => {
      const { _id } = order;
      const formattedData = new OrderPosMis({
        rowNum: row,
        id: _id, // eslint-disable-line
        delivered: order.actualDeliveredTime ? 'Yes' : 'No',
        articleCode: order.posItems.hsnCode || '',
        articleDescription: order.posItems.itemName || '',
        quantity: order.posItems.quantity || '',
        UOM: order.posItems.UOM || '',
        itemCategory: order.posItems.itemCategory || 'NA',
        amount: order.posItems.amount || '',
        customerMobile: order.customerMobile || '',
        storeid: order.storeid || '',
        storeName: order.storeName || '',
        createdAt: order.createdAt || '',
        otpGeneratedTime: order.posPingTime ? order.posPingTime : '',
        otpFeedTime: order.actualDeliveredTime ? order.actualDeliveredTime : '',
        tillNumber: order.tillNumber ? order.tillNumber : 'NA',
        billNumber: order.billNumber ? order.billNumber : 'NA',
        itemCode: order.posItems.itemCode ? order.posItems.itemCode.replace(/^0+/, '') : 'NA',
        coupon: order.coupon ? order.coupon : 'NA'

      });
      row += 1;
      return formattedData;
    });
    state.pagination = pagination;
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
