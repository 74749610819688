<template>
  <div class="app-layout app-layout-ecatalogue">
    <e-catalogue-header :open="showModal" :openOrder="showModalNew" />
    <b-container fluid>
      <slot />
    </b-container>
    <b-modal
      hide-header-close
      @show="resetModal"
      @hidden="resetModal"
      ref="my-modal"
      title="Enter Your Area Pincode"
      hide-footer
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          label="Pincode"
          label-for="pincode-input"
          invalid-feedback="Pincode is required"
          :state="pincodeState"
        >
          <div>
            <b-form-input
              id="pincode-input"
              v-model="inputData"
              :state="pincodeState"
              placeholder="Enter your 6 digit Pincode."
              required
            >
            </b-form-input>
            <b-button
              class="mt-2"
              :disabled="inputData === '' ? true : false"
              style="marigin-left: 10px"
              variant="success"
              @click="searchStore"
              >Get Stores
            </b-button>
          </div>
        </b-form-group>
      </form>
      <div>
        <div v-if="storeList.length > 0">
          <h5>Please select a store</h5>
          <ul class="list-group" v-for="store in storeList" :key="store.id">
            <li class="list-group-item storesList" @click="() => selectStore(store)"> {{ store.storeName }} </li>
          </ul>
        </div>
        <div v-else>
          <div v-if="hasSearched">
            <h5>No Stores Found.</h5>
            <p style="color: red">We are Live Soon In Your Area. Please Try with another Pincode.</p>
          </div>
        </div>
      </div>
    </b-modal>
    <br />
    <b-modal
      hide-header-close
      ref="my-modal-new"
      title="Enter Your Mobile Number"
      @show="resetModalNew"
      @hidden="resetModalNew"
      hide-footer
    >
      <b-overlay :show="busy" rounded="sm">
        <b-overlay :show="newBusy" rounded="sm">
          <form ref="formNew" @submit.stop.prevent="handleSubmit">
            <b-form-group
              label="Mobile Number"
              label-for="mobile-input"
              invalid-feedback="Mobile Number is required"
              :state="mobileState"
            >
              <div style="display: flex">
                <b-form-input
                  id="mobile-input"
                  v-model="inputMobile"
                  :state="mobileState"
                  :formatter="limit"
                  placeholder="Enter your Mobile Number"
                  required
                  style="max-width: 270px"
                >
                </b-form-input>
                <b-button style="margin-left: 10px" variant="success" @click="searchOrders">Search Orders</b-button>
              </div>
            </b-form-group>
          </form>
          <div>
            <div v-if="orders.length === 0">
              <div v-if="hasSearchedNew">
                <p style="color: red">No orders found for entered mobile number.</p>
              </div>
            </div>
          </div>
        </b-overlay>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import ECatalogueHeader from '@/components/ECatalogueHeader.vue';
import { mapState, mapActions } from 'vuex';

export default {
  components: { ECatalogueHeader },
  name: 'ECatalogueLayout',
  data() {
    return {
      inputData: '',
      stores: [],
      pincodeState: null,
      storeData: null,
      hasSearched: false,
      inputMobile: '',
      mobileState: null,
      hasSearchedNew: false,
      busy: false,
      newBusy: false,
      timeOut: null
    };
  },
  beforeRouteUpdate(to, form, next) {
    this.listStore({ query: to.query });
    next();
  },
  computed: {
    ...mapState('product', ['storeList', 'orders'])
  },
  methods: {
    ...mapActions('product', ['listStore', 'listOrders']),
    limit(e) {
      return e.slice(0, 10);
    },
    selectStore(store) {
      this.hideModal();
      this.storeData = store;
      window.localStorage.setItem('storeData', JSON.stringify(store));
      window.location.href = window.location.href
        .split('?')
        .map((url, i) =>
          !i
            ? url
            : url
                .split('&')
                .filter(p => !p.startsWith('pincode='))
                .join('&')
        )
        .join('?');
    },
    resetModal() {
      this.inputData = '';
      this.pincodeState = null;
    },
    showModal() {
      this.$refs['my-modal'].show();
    },
    hideModal() {
      this.$refs['my-modal'].hide();
    },
    searchStore() {
      if (!this.checkFormValidity()) {
        return;
      }
      // Push the name to submitted names
      this.stores.push(this.inputData);
      this.listStore({
        query: {
          pincode: this.inputData
        }
      });
      this.hasSearched = true;
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.pincodeState = valid;
      return valid;
    },
    resetModalNew() {
      this.inputMobile = '';
      this.mobileState = null;
    },
    showModalNew() {
      this.$refs['my-modal-new'].show();
    },
    searchOrders() {
      // Exit if the form isn't valid
      if (!this.checkFormValidity2()) {
        return;
      }

      this.busy = true;
      this.setTimeout(() => {
        this.busy = false;
        this.listOrders({
          query: {
            mobile: this.inputMobile
          }
        });
        this.hasSearchedNew = true;
        this.newBusy = true;
        this.setTimeout(() => {
          this.newBusy = false;
          if (this.orders.length > 0) {
            this.$router.push('/eOrderHistory');
            window.localStorage.setItem('orders', JSON.stringify(this.orders));
          }
        });
      });
    },
    checkFormValidity2() {
      const valid = this.$refs.formNew.checkValidity();
      const validLength = this.inputMobile.length === 10;
      this.mobileState = valid && validLength;
      return valid && validLength;
    },
    clearTimeout() {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
    },
    setTimeout(callback) {
      this.clearTimeout();
      this.timeout = setTimeout(() => {
        this.clearTimeout();
        callback();
      }, 1000);
    }
  }
};
</script>
