export default class MIS {
  constructor({
    row,
    storeid,
    region,
    cluster,
    callLandedTotalCount,
    callsLandedAnswered,
    callsLandedNotAnswered,
    callsLandedBusy,
    callsLandedFailed,
    callsFeedbackNotSelected,
    callsFeedbackServiced,
    callsFeedbackNotAvailable,
    callsFeedbackEnquiryCall,
    callsFeedbackOutOfArea,
    callsFeedbackNotReachable,
    singleOrderCount,
    bulkOrderCount,
    // outboundOrderCount,
    acknowledgeCount,
    deliveredCount,
    callsRecording,
    callsRecNotSelected,
    callsRecServiced,
    callsRecNotAvailable,
    callsRecEnquiryCall,
    callsRecOutOfArea,
    callsRecNotReachable,
    // outboundCallDialed,
    ivrOrderCount
    // outboundCallConnected,
    // outboundTerminatedByStaff,
    // outboundRingingNoOutcome,
    // outboundUserNotAvailable
  }) {
    this.row = row;
    this.storeid = storeid;
    this.region = region;
    this.cluster = cluster;
    this.callLandedTotalCount = callLandedTotalCount;
    this.callsLandedAnswered = callsLandedAnswered;
    this.callsLandedNotAnswered = callsLandedNotAnswered;
    this.callsLandedBusy = callsLandedBusy;
    this.callsLandedFailed = callsLandedFailed;
    this.callsFeedbackNotSelected = callsFeedbackNotSelected;
    this.callsFeedbackServiced = callsFeedbackServiced;
    this.callsFeedbackNotAvailable = callsFeedbackNotAvailable;
    this.callsFeedbackEnquiryCall = callsFeedbackEnquiryCall;
    this.callsFeedbackOutOfArea = callsFeedbackOutOfArea;
    this.callsFeedbackNotReachable = callsFeedbackNotReachable;
    this.singleOrderCount = singleOrderCount;
    this.bulkOrderCount = bulkOrderCount;
    // this.outboundOrderCount = outboundOrderCount;
    this.ivrOrderCount = ivrOrderCount;
    this.acknowledgeCount = acknowledgeCount;
    this.deliveredCount = deliveredCount;
    this.callsRecording = callsRecording;
    this.callsRecNotSelected = callsRecNotSelected;
    this.callsRecServiced = callsRecServiced;
    this.callsRecNotAvailable = callsRecNotAvailable;
    this.callsRecEnquiryCall = callsRecEnquiryCall;
    this.callsRecOutOfArea = callsRecOutOfArea;
    this.callsRecNotReachable = callsRecNotReachable;
    // this.outboundCallDialed = outboundCallDialed;
    // this.outboundCallConnected = outboundCallConnected;
    // this.outboundTerminatedByStaff = outboundTerminatedByStaff;
    // this.outboundRingingNoOutcome = outboundRingingNoOutcome;
    // this.outboundUserNotAvailable = outboundUserNotAvailable;
  }
}
