import _ from 'lodash';
import misService from '@/services/misService';
import MIS from '../../model/mis';

const state = {
  baseUrl: '/order',
  misList: [],
  misChart: [],
  misTable: []
};

const actions = {
  download({ dispatch, commit }, { query = {}, download = true }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    commit('setDownloadMisList', { misList: [] });
    misService
      .list({ query })
      .then(response => {
        if (download) {
          commit('setDownloadMisList', { misList: response.data });
        } else {
          commit('setMisChart', { misChart: response.data });
          commit('setMisTable', { misTable: response.data });
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  finishRequest(state) {
    state.loading = false;
  },
  setMisChart(state, { misChart }) {
    let row = 1;
    state.misChart = _.map(misChart, mis => {
      const formattedList = new MIS({
        row,
        storeid: mis.storeid,
        region: mis.region,
        cluster: mis.cluster,
        callLandedTotalCount: mis.callLandedTotalCount,
        callsLandedAnswered: mis.callsLandedAnswered,
        callsLandedNotAnswered: mis.callsLandedNotAnswered,
        callsLandedBusy: mis.callsLandedBusy,
        callsLandedFailed: mis.callsLandedFailed,
        callsFeedbackNotSelected: mis.callsFeedbackNotSelected,
        callsFeedbackServiced: mis.callsFeedbackServiced,
        callsFeedbackNotAvailable: mis.callsFeedbackNotAvailable,
        callsFeedbackEnquiryCall: mis.callsFeedbackEnquiryCall,
        callsFeedbackOutOfArea: mis.callsFeedbackOutOfArea,
        callsFeedbackNotReachable: mis.callsFeedbackNotReachable,
        singleOrderCount: mis.singleOrderCount,
        bulkOrderCount: mis.bulkOrderCount,
        acknowledgeCount: mis.acknowledgeCount,
        deliveredCount: mis.deliveredCount,
        outboundOrderCount: mis.outboundOrderCount,
        ivrOrderCount: mis.ivrOrderCount,
        callsRecording: mis.callsRecording,
        callsRecNotSelected: mis.callsRecNotSelected,
        callsRecServiced: mis.callsRecServiced,
        callsRecNotAvailable: mis.callsRecNotAvailable,
        callsRecEnquiryCall: mis.callsRecEnquiryCall,
        callsRecOutOfArea: mis.callsRecOutOfArea,
        callsRecNotReachable: mis.callsRecNotReachable,
        outboundCallDialed: mis.outboundCallDialed,
        outboundCallConnected: mis.outboundCallConnected,
        outboundTerminatedByStaff: mis.outboundTerminatedByStaff,
        outboundRingingNoOutcome: mis.outboundRingingNoOutcome,
        outboundUserNotAvailable: mis.outboundUserNotAvailable
      });
      row += 1;
      return formattedList;
    });
    state.loading = false;
  },
  setMisTable(state, { misTable }) {
    let row = 1;
    state.misTable = _.map(misTable, mis => {
      const formattedList = new MIS({
        row,
        storeid: mis.storeid,
        region: mis.region,
        cluster: mis.cluster,
        callLandedTotalCount: mis.callLandedTotalCount,
        callsLandedAnswered: mis.callsLandedAnswered,
        callsLandedNotAnswered: mis.callsLandedNotAnswered,
        callsLandedBusy: mis.callsLandedBusy,
        callsLandedFailed: mis.callsLandedFailed,
        callsFeedbackNotSelected: mis.callsFeedbackNotSelected,
        callsFeedbackServiced: mis.callsFeedbackServiced,
        callsFeedbackNotAvailable: mis.callsFeedbackNotAvailable,
        callsFeedbackEnquiryCall: mis.callsFeedbackEnquiryCall,
        callsFeedbackOutOfArea: mis.callsFeedbackOutOfArea,
        callsFeedbackNotReachable: mis.callsFeedbackNotReachable,
        singleOrderCount: mis.singleOrderCount,
        bulkOrderCount: mis.bulkOrderCount,
        acknowledgeCount: mis.acknowledgeCount,
        deliveredCount: mis.deliveredCount,
        outboundOrderCount: mis.outboundOrderCount,
        ivrOrderCount: mis.ivrOrderCount,
        callsRecording: mis.callsRecording,
        callsRecNotSelected: mis.callsRecNotSelected,
        callsRecServiced: mis.callsRecServiced,
        callsRecNotAvailable: mis.callsRecNotAvailable,
        callsRecEnquiryCall: mis.callsRecEnquiryCall,
        callsRecOutOfArea: mis.callsRecOutOfArea,
        callsRecNotReachable: mis.callsRecNotReachable,
        outboundCallDialed: mis.outboundCallDialed,
        outboundCallConnected: mis.outboundCallConnected,
        outboundTerminatedByStaff: mis.outboundTerminatedByStaff,
        outboundRingingNoOutcome: mis.outboundRingingNoOutcome,
        outboundUserNotAvailable: mis.outboundUserNotAvailable
      });
      row += 1;
      return formattedList;
    });
    state.loading = false;
  },
  setDownloadMisList(state, { misList }) {
    let row = 1;
    state.misList = _.map(misList, mis => {
      const formattedList = new MIS({
        row,
        storeid: mis.storeid,
        region: mis.region,
        cluster: mis.cluster,
        callLandedTotalCount: mis.callLandedTotalCount,
        callsLandedAnswered: mis.callsLandedAnswered,
        callsLandedNotAnswered: mis.callsLandedNotAnswered,
        callsLandedBusy: mis.callsLandedBusy,
        callsLandedFailed: mis.callsLandedFailed,
        callsFeedbackNotSelected: mis.callsFeedbackNotSelected,
        callsFeedbackServiced: mis.callsFeedbackServiced,
        callsFeedbackNotAvailable: mis.callsFeedbackNotAvailable,
        callsFeedbackEnquiryCall: mis.callsFeedbackEnquiryCall,
        callsFeedbackOutOfArea: mis.callsFeedbackOutOfArea,
        callsFeedbackNotReachable: mis.callsFeedbackNotReachable,
        singleOrderCount: mis.singleOrderCount,
        bulkOrderCount: mis.bulkOrderCount,
        acknowledgeCount: mis.acknowledgeCount,
        deliveredCount: mis.deliveredCount,
        outboundOrderCount: mis.outboundOrderCount,
        ivrOrderCount: mis.ivrOrderCount,
        callsRecording: mis.callsRecording,
        callsRecNotSelected: mis.callsRecNotSelected,
        callsRecServiced: mis.callsRecServiced,
        callsRecNotAvailable: mis.callsRecNotAvailable,
        callsRecEnquiryCall: mis.callsRecEnquiryCall,
        callsRecOutOfArea: mis.callsRecOutOfArea,
        callsRecNotReachable: mis.callsRecNotReachable,
        outboundCallDialed: mis.outboundCallDialed,
        outboundCallConnected: mis.outboundCallConnected,
        outboundTerminatedByStaff: mis.outboundTerminatedByStaff,
        outboundRingingNoOutcome: mis.outboundRingingNoOutcome,
        outboundUserNotAvailable: mis.outboundUserNotAvailable
      });
      row += 1;
      return formattedList;
    });
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
