import _ from 'lodash';
import axios from 'axios';
import configService from '@/services/configService';
import utils from '@/helper/utils';

export default {
  async listStore({ query = {} }) {
    const pickedQuery = _.pick(query, ['type', 'by', 'value']);
    let url = `${configService.get('apiUrl')}/store`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async getOne({ type = 'store', id }) {
    return axios
      .get(`${configService.get('apiUrl')}/${type}/${id}`, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async postOne({ type = 'store', store } = {}) {
    return axios
      .post(`${configService.get('apiUrl')}/${type}`, store)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },

  async patchOne({ type = 'store', id, store }) {
    return axios
      .patch(`${configService.get('apiUrl')}/${type}/${id}`, store)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async deleteOne({ type = 'store', id }) {
    return axios
      .delete(`${configService.get('apiUrl')}/${type}/${id}`)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  }
};
