import _ from 'lodash';
import axios from 'axios';
import configService from '@/services/configService';
import utils from '@/helper/utils';

export default {
  async listBanner({ query = {} } = {}) {
    const pickedQuery = _.pick(query, ['q', 'startdate', 'enddate', 'search', 'searchkey', 'page',
      'page_size',
      'filter']);
    let url = `${configService.get('apiUrl')}/productBanner`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async deleteOne({ id }) {
    return axios
      .delete(`${configService.get('apiUrl')}/productBanner/${id}`)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },

  async postOne({ productBanner } = {}) {
    return axios
      .post(`${configService.get('apiUrl')}/productBanner/`, productBanner)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },

  async getOne({ id }) {
    return axios
      .get(`${configService.get('apiUrl')}/productBanner/${id}/`, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },

  async patchOne({ id, productBanner } = {}) {
    return axios
      .patch(`${configService.get('apiUrl')}/productBanner/${id}/`, productBanner)
      .then(response => {
        return response.data;
      }).catch(e => {
        throw e;
      });
  },

  async listCatalogueBanner({ query = {} } = {}) {
    const pickedQuery = _.pick(query, ['q', 'storeId']);
    let url = `${configService.get('apiUrl')}/customerBanner`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  }
};
