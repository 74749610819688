<template>
  <div class="header_div">
    <div class="header_logo_div">
      <div>
        <div id="sidemenu">
          <button
            id="sidemenu_btn"
            class="sidemenu__btn"
            v-on:click="navOpen = !navOpen"
            v-bind:class="{ active: navOpen }"
          >
            <span class="top"></span>
            <span class="mid"></span>
            <span class="bottom"></span>
          </button>
          <transition name="translateX">
            <nav v-show="navOpen">
              <div class="sidemenu__wrapper">
                <ul class="sidemenu__list">
                  <li class="sidemenu__item"
                    ><a @click="openPincodeModal()">
                      <font-awesome-icon :icon="['fas', 'map-marker-alt']" style="color: #f28a3f" class="fa-lg" />
                      {{
                        selectedStore.storeName && selectedStore.storeName.length > 12
                          ? selectedStore.storeName.substring(0, 12) + '...'
                          : selectedStore.storeName
                      }}
                    </a></li
                  >

                  <li v-if="isECatalogueEntry" class="sidemenu__item"><a @click="openOrderModal">My Order</a></li>
                  <li class="sidemenu__item"
                    ><a @click="openCart()">Checkout {{ cartItemsCount === 0 ? '' : cartItemsCount }} </a></li
                  >
                </ul>
              </div>
            </nav>
          </transition>
        </div>
      </div>
      <div v-bind:class="cartItemsCount === 0 ? 'noView' : 'cartView'">
        <button @click="openCart()" class="cartViewBtn" style="">
          <font-awesome-icon :icon="['fas', 'cart-plus']" class="fa-lg" />
        </button>
      </div>
      <div class="spencer_logo">
        <a @click="goHome()">
          <img class="img" src="../assets/images/logo.png" alt="logo" />
        </a>
      </div>

      <div class="myLocation">
        <a class="selection" @click="openPincodeModal()">
          <div class="location" style="align-self: center">
            <font-awesome-icon :icon="['fas', 'map-marker-alt']" style="color: #f28a3f" class="fa-lg" />
          </div>
          <div class="storeSelection"> {{ selectedStore.storeName }} </div>
        </a>
      </div>
      <div class="search_bar">
        <div class="search_input">
          <font-awesome-icon :icon="['fas', 'search']" style="color: #f28a3f" class="mr-3 search_icon" />
          <input
            v-model="searchInput"
            v-on:keyup.enter="onSearch(searchInput)"
            type="text"
            placeholder="Search for Atta, dal, rice ..."
          />
        </div>
      </div>
      <div v-if="isECatalogueEntry" class="order-button">
        <b-button class="orderBtn" @click="openOrderModal">My Orders</b-button>
      </div>
      <div class="order-button">
        <b-button @click="openCart()" class="orderBtn"
          >Checkout {{ cartItemsCount === 0 ? '' : cartItemsCount }}</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import router from '@/router';

export default {
  name: 'ECatalogueHeader',
  data() {
    return {
      selectedStore: {},
      searchInput: '',
      cartItemsCount: 0,
      navOpen: false,
      defaultStore: {
        pincodes: ['734004'],
        storeName: 'SPENCERS WHOLESALE BAZAAR (B2B)',
        storeid: 'S117'
      }
    };
  },
  props: {
    open: {
      type: Function,
      required: true
    },
    openOrder: {
      type: Function,
      required: true
    }
  },
  mounted() {
    if (!localStorage.getItem('storeData')) {
      localStorage.setItem('storeData', JSON.stringify(this.defaultStore));
    }
    const selectedStoreData = localStorage.getItem('storeData');
    if (selectedStoreData) {
      this.selectedStore = JSON.parse(selectedStoreData);
    } else {
      this.selectedStore = {};
    }
    const cartItemsData = localStorage.getItem('product-cart') || '{}';
    this.setCartItem({ cartItems: JSON.parse(cartItemsData) });
  },
  computed: {
    ...mapState('product', ['cartItems']),
    isECatalogueEntry() {
      const currentPath = this.$route.path;
      if (
        currentPath === '/eCategoryList' ||
        currentPath === '/eSubCategoryList' ||
        currentPath === '/eSearchProduct'
      ) {
        return true;
      }
      return false;
    }
  },
  methods: {
    ...mapActions('product', ['setCartItem']),
    openOrderModal() {
      this.openOrder();
      this.navOpen = !this.navOpen;
    },

    openPincodeModal() {
      this.open();
    },
    openCart() {
      const currentPath = this.$route.path;
      if (
        currentPath === '/eCategoryList' ||
        currentPath === '/eSubCategoryList' ||
        currentPath === '/eSearchProduct'
      ) {
        router.push(`/eProduct-cata`);
      } else {
        router.push(`/product-cata`);
        this.navOpen = !this.navOpen;
      }
    },
    goHome() {
      if (this.isECatalogueEntry) {
        router.push(`/eCategoryList`);
      } else {
        router.push(`/categoryList`);
      }
    },
    onSearch(searchInput) {
      const currentPath = this.$route.path;
      if (
        currentPath === '/eCategoryList' ||
        currentPath === '/eSubCategoryList' ||
        currentPath === '/eSearchProduct'
      ) {
        router.push(`/eSearchProduct?query=${searchInput}`).catch(() => {});
      } else router.push(`/searchProduct?query=${searchInput}`).catch(() => {});
    },
    closeIfClickedOutside(event) {
      const close = document.getElementById('sidemenu_btn');
      if (!close?.contains(event.target)) {
        this.navOpen = false;
      }
    }
  },
  watch: {
    cartItems(newValue) {
      this.cartItemsCount = Object.values(newValue).reduce((count, item) => {
        return count + item.quantity;
      }, 0);
    },
    navOpen(navOpen) {
      if (navOpen) {
        document.addEventListener('click', this.closeIfClickedOutside);
      }
    }
  }
};
</script>

<style scoped>
.noView {
  display: none;
}
.cartView {
  width: 50px;
  border-radius: 50px;
  overflow: hidden;
  height: 48px;
  display: none;
  background-color: #e7861b;
  align-content: center;
  position: fixed;
  right: 20px;
  bottom: 70px;
  z-index: 200;
}
.cartViewBtn {
  align-self: center;
  border: none;
  width: 100%;
  background-color: #e7861b;
  color: white;
}
.header_div {
  margin-top: -4.5rem;
  margin-bottom: 1rem;
  /* border: 1px solid black; */
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  font-family: Myriad;
}

.header_logo_div {
  display: flex;
  position: relative;
  gap: 10px;
  position: relative;
  /* border: 1px solid black; */
}

.selection {
  text-decoration: none;
  color: #949494;
  padding: 5px;
  display: flex;
  gap: 5px;
  position: relative;
}

.storeSelection {
  font-size: 15px;
  align-self: center;
  /* margin-right: 10; */
}

.storeSelection:hover {
  cursor: pointer;
}
.search_bar {
  margin: auto;
  /* margin-right: 10px; */
  border-radius: 10px;
  border: 1px solid #949494;
  width: 50%;
  position: relative;
  align-self: center;
  padding: 3px;
}
a {
  text-decoration: none;
  color: white;
  text-align: center;
  padding: 5.5px;
  margin: auto;
  border-radius: 4px;
}
.profileBtn {
  /* background-color: #f28a3f; */
  /* border: 1px solid black; */
  width: 100%;
  /* height: 100%; */
  margin-right: 3px;
  padding: 2px;
  margin-block: auto;
  text-align: center;
  font-size: 17px;
  font-weight: bold;
  color: #f28a3f;
}
.orderBtn {
  background-color: #f28a3f;
  border: none;
  width: 100%;
  padding: 2px;
  cursor: none;
}
.profile-button {
  /* justify-content: center; */
  /* align-self: center; */
  display: flex;
  /* border: 1px solid black; */
  /* width: 180px; */
  /* font-family: Myriad; */
}
.order-button {
  justify-content: center;
  align-self: center;
  width: 120px;
  /* font-family: Myriad; */
}
a:hover {
  cursor: pointer;
}
#sidemenu {
  display: none;
}

.search_input {
  display: flex;
}
.search_input > input {
  border: none;
  width: 15rem;
  padding: 5px;
  border-radius: 3px;
  /* border: 1px solid #f28a3f; */
  width: 90%;
}

.search_input > input:focus {
  outline: none;
}

.search_icon {
  color: black;
  padding: 1px;
  margin-left: 6px;
  align-self: center;
}

.myLocation {
  margin: 0 0 0 10px;
  display: flex;
  max-width: 250px;
}
#sidemenu nav {
  width: 150px;
  background: #fff;
  position: fixed;
  top: 1;
  left: 1;
  z-index: 101;
}
#sidemenu .sidemenu__btn {
  display: block;
  width: 50px;
  height: 50px;
  background: #fff;
  border: none;
  position: relative;
  z-index: 100;
  appearance: none;
  cursor: pointer;
  outline: none;
}
#sidemenu .sidemenu__btn span {
  display: block;
  width: 20px;
  height: 2px;
  margin: auto;
  background: grey;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all 0.4s ease;
}
#sidemenu .sidemenu__btn span.top {
  transform: translateY(-8px);
}
#sidemenu .sidemenu__btn span.bottom {
  transform: translateY(8px);
}
#sidemenu .sidemenu__btn.active .top {
  transform: rotate(-45deg);
}
#sidemenu .sidemenu__btn.active .mid {
  transform: translateX(-20px) rotate(360deg);
  opacity: 0;
}
#sidemenu .sidemenu__btn.active .bottom {
  transform: rotate(45deg);
}
#sidemenu .sidemenu__wrapper {
  /* padding-top: 2px; */
  /* border: 1px solid; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
#sidemenu .sidemenu__list {
  padding-top: 50px;
  list-style: none;
  padding: 0;
  margin: 0;
}
#sidemenu .sidemenu__item a {
  text-decoration: none;
  line-height: 1.6em;
  font-size: 15px;
  padding: 10px;
  display: block;
  color: black;
  transition: 0.4s ease;
  border-top: 0.5px solid #f28a3f;
}
#sidemenu .sidemenu__item a:hover {
  background: lightgrey;
  color: dimgrey;
}
.translateX-enter {
  transform: translateX(-200px);
  opacity: 0;
}

.translateX-enter-active,
.translateX-leave-active {
  transform-origin: top left 0;
  transition: 0.2s ease;
}

.translateX-leave-to {
  transform: translateX(-200px);
  opacity: 0;
}
.spencer_logo {
  align-self: center;
}

.img {
  width: 150px;
}

@media screen and (max-width: 600px) {
  .selection {
    margin: auto;
  }
  .myLocation {
    display: none;
  }
  .order-button {
    display: none;
  }
  .header_logo_div {
    justify-content: center;
  }
  #sidemenu {
    display: block;
  }
  .cartView {
    display: flex;
  }

  .img {
    width: 100px;
  }
}
@media screen and (max-width: 1047px) {
  a {
    padding: 2px;
  }
}
@media screen and (max-width: 900px) {
  a {
    padding: 0px;
  }
}
</style>
